import { Tooltip, colors } from '@dspace-internal/ui-kit'
import { Box, Stack } from '@mui/material'
import React from 'react'
import { convertUTCToLocal } from '../../../utils'
import { RelativeTime } from '../../RelativeTime'

const coerceDate = (val: number | string) =>
  typeof val === 'number' ? new Date(val * 1000) : new Date(val)

interface ChangeInformationProps {
  createdOn: number | string | null | undefined
  createdById: string
  createdByName: string
  modifiedOn: number | string | null | undefined
  modifiedById: string
  modifiedByName: string
  noPadding?: boolean
  /** @deprecated this will be removed. components should not make assumptions about how they are being layouted in the parent contexts */
  alignment?: 'start' | 'end'
}

export const ChangeInformation: React.FC<ChangeInformationProps> = ({
  createdOn,
  createdById,
  createdByName,
  modifiedOn,
  modifiedById,
  modifiedByName,
  noPadding = false,
  alignment = 'end',
}) => {
  const createdBy = createdByName || createdById
  const modifiedBy = modifiedByName || modifiedById

  // showModifiedInformation indicates whether modified or created information are shown
  // in the change information component.
  // true -> show modified information, false -> show created information
  let showModifiedInformation = false
  if (modifiedOn || modifiedByName || modifiedById) {
    showModifiedInformation = true
  } else if (createdOn || createdByName || createdById) {
    showModifiedInformation = false
  } else {
    return <></>
  }

  // show modified information if it's younger than created
  // or if its triggered by different user (use case: system user)
  if (createdOn && modifiedOn) {
    showModifiedInformation = modifiedOn > createdOn || modifiedBy !== createdBy
    if (modifiedBy !== createdBy) {
      showModifiedInformation = true
    }
  }

  const tooltipMessage = () => {
    const createdDate = createdOn ? convertUTCToLocal(createdOn) : '-'
    const modifiedDate = modifiedOn ? convertUTCToLocal(modifiedOn) : '-'

    return (
      <Stack direction="column" gap={3}>
        {(modifiedOn !== createdOn || modifiedBy !== createdBy) && (
          <div>
            Modified: {modifiedDate}
            <div>{modifiedBy ? `by ${modifiedBy}` : ''}</div>
          </div>
        )}
        <div>
          Created: {createdDate}
          <div>{createdBy ? `by ${createdBy}` : ''}</div>
        </div>
      </Stack>
    )
  }

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: alignment,
        color: colors.gray_80,
        fontSize: '14px',
        padding: noPadding ? undefined : '0px 20px 16px',
      }}
    >
      <Tooltip message={tooltipMessage()} placement="bottom">
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          data-testid="changeInfoTooltip"
        >
          {showModifiedInformation ? (
            <span>
              Modified{' '}
              {modifiedOn ? (
                <RelativeTime
                  preventTooltip
                  underline
                  date={coerceDate(modifiedOn)}
                />
              ) : null}
              {modifiedBy && ` by ${modifiedBy}`}
            </span>
          ) : (
            <span>
              Created{' '}
              {createdOn ? (
                <RelativeTime
                  preventTooltip
                  underline
                  date={coerceDate(createdOn)}
                />
              ) : null}
              {createdBy && ` by ${createdBy}`}
            </span>
          )}
        </Stack>
      </Tooltip>
    </Box>
  )
}

export default ChangeInformation
