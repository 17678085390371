import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  PATHS,
  SidebarItemInterpolate,
  TestEnvironmentIcon,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'

const TestEnvironmentOverview = React.lazy(
  () => import('./components/TestEnvironmentListPage')
)
const TestEnvironmentDetailsPage = React.lazy(
  () => import('./components/TestEnvironmentDetailsPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.TESTENVIRONMENT_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="Test Environments"
      to={PATHS.TESTENVIRONMENTS.LIST(':projectId')}
      icon={<TestEnvironmentIcon />}
    />,
  ],
  routes: [
    {
      path: PATHS.TESTENVIRONMENTS.LIST(':projectId'),
      element: wrapComponentInProviders(TestEnvironmentOverview),
    },
    {
      path: PATHS.TESTENVIRONMENTS.DETAIL(':projectId', ':testEnvironmentId'),
      element: wrapComponentInProviders(TestEnvironmentDetailsPage),
    },
  ],
}
