import {
  Button,
  Paper,
  configureBreadcrumb,
  showSnackbar,
} from '@dspace-internal/ui-kit'
import { CopyAll, Save } from '@mui/icons-material'
import { Alert, IconButton, Stack, Typography } from '@mui/material'
import {
  ApiKeyService,
  AuthManagerApiError,
} from '@simphera/shared/rest-clients'
import {
  PATHS,
  SimpheraPageHeader,
  usePrompt,
  useSimpheraBreadcrumbs,
} from '@simphera/shared/ui-simphera'
import { addYears } from 'date-fns'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import messages from '../../utils/messages.res'
import { DescriptionInput } from './DescriptionInput'
import { ExpirationDateInput } from './ExpirationDateInput'
import { NameInput } from './NameInput'

interface FormFields {
  expirationDate: string
  name: string
  description: string
}

const ONE_YEAR_FROM_NOW_YYYY_MM_DD = addYears(new Date(), 1)
  .toISOString()
  .split('T')[0]

const defaultValues: FormFields = {
  expirationDate: ONE_YEAR_FROM_NOW_YYYY_MM_DD,
  name: '',
  description: '',
}

export const ApiKeysCreatePage = () => {
  const formProps = useForm<FormFields>({
    defaultValues,
    mode: 'all',
  })
  const { formState, handleSubmit, reset } = formProps
  const [apiKey, setApiKey] = React.useState<string | null>(null)

  usePrompt(messages.UNSAVED_CHANGES(), formState.isDirty)

  useSimpheraBreadcrumbs([
    configureBreadcrumb('API keys', PATHS.APIKEYS.LIST()),
  ])

  const handleSave: SubmitHandler<FormFields> = (formData) => {
    if (formState.isSubmitting || formState.isSubmitted) {
      return
    }

    ApiKeyService.postApiKeys({
      requestBody: {
        name: formData.name,
        description: formData.description,
        expirationDate: new Date(formData.expirationDate).toISOString(),
      },
    })
      .then((data) => {
        setApiKey(data.apiKey)
        reset(defaultValues)
        showSnackbar({
          message: messages.API_KEY_CREATED(),
          appearance: 'info',
        })
      })
      .catch((error: AuthManagerApiError) => {
        console.error(error)
        showSnackbar({
          message: messages.API_KEY_CREATION_ERROR(),
          appearance: 'error',
        })
      })
  }

  const copyApiKeyToClipboard = () => {
    navigator.clipboard.writeText(apiKey!)
    showSnackbar({
      message: messages.API_KEY_COPIED_TO_CLIPBOARD(),
      appearance: 'success',
    })
  }

  return (
    <>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(handleSave)}>
          <SimpheraPageHeader
            subtitle="API keys"
            title="Create API key"
            primaryAction={
              <Button
                label="Save"
                type="submit"
                color="primary"
                icon={<Save />}
              />
            }
          />
          <Paper>
            <Stack direction="column" gap={2}>
              <NameInput />
              <DescriptionInput />
              <ExpirationDateInput />
            </Stack>
          </Paper>
          {apiKey && (
            <Paper sx={{ marginTop: 4 }}>
              {messages.API_KEY_ADDED()}
              <Stack direction="row" alignItems="center" gap={2} marginY={4}>
                <Typography
                  fontFamily="monospace"
                  fontSize="14px"
                  fontWeight="600"
                >
                  {apiKey}
                </Typography>
                <IconButton
                  aria-label="Copy"
                  size="small"
                  color="primary"
                  onClick={copyApiKeyToClipboard}
                >
                  <CopyAll fontSize="inherit" />
                </IconButton>
              </Stack>
              <Alert severity="warning">{messages.API_KEY_COPY_HINT()}</Alert>
            </Paper>
          )}
        </form>
      </FormProvider>
    </>
  )
}
