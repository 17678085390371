import { useDraggable } from '@dnd-kit/core'

import DragIndicator from '@mui/icons-material/DragIndicator'
import { TextWithOverflow } from '@simphera/shared/ui-simphera'
import {
  ParameterDraggableContainer,
  ParameterDraggableContainerProps,
} from './ParameterDraggable.styles'

export const ParameterDraggablePresentation: React.FC<
  ParameterDraggableProps & ParameterDraggableContainerProps
> = ({ parameterName, ...restProps }) => {
  return (
    <ParameterDraggableContainer {...restProps}>
      <DragIndicator />
      <TextWithOverflow disableTooltip={restProps.grabbed}>
        {parameterName}
      </TextWithOverflow>
    </ParameterDraggableContainer>
  )
}

export interface ParameterDraggableProps {
  parameterName: string
}

export const ParameterDraggable: React.FC<ParameterDraggableProps> = ({
  parameterName,
}) => {
  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: parameterName,
  })

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      <ParameterDraggablePresentation
        parameterName={parameterName}
        hide={isDragging}
        grabbed={false}
      />
    </div>
  )
}
