import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/operation'
import { applyClientConfig } from '../clientConfig'

applyClientConfig(OpenAPI, { baseUrl: GlobalConfig.coreService.REST_API_URL })

export {
  OperationsService,
  type Operation,
  type OperationResultList,
  type OperationStatus,
} from '../../generated/operation'

export type OperationsApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
