import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  pointerWithin,
} from '@dnd-kit/core'
import { Dialog } from '@dspace-internal/ui-kit'
import { mdiAxisXArrow, mdiAxisYArrow, mdiAxisZArrow } from '@mdi/js'
import { Box } from '@mui/material'
import { HelpText } from '@simphera/shared/ui-simphera'
import { useEffect, useState } from 'react'
import { captions_res } from '../../../../resources/captions'
import descriptions_res from '../../../../resources/descriptions.res'
import { ParameterDraggablePresentation } from './ParameterDraggable'
import { SelectedParameterDroppable } from './SelectedParameterDroppable'
import { UnplottedParametersDroppable } from './UnplottedParametersDroppable'

export interface SelectedParameterAxes {
  x: string | undefined
  y: string | undefined
  z: string | undefined
}

const xAxisDroppableId = 'xAxisParameter'
const yAxisDroppableId = 'yAxisParameter'
const zAxisDroppableId = 'zAxisParameter'

const selectedParametersDroppables = [
  xAxisDroppableId,
  yAxisDroppableId,
  zAxisDroppableId,
]

interface SelectedParametersState {
  [axisKey: string]: string | undefined
  [xAxisDroppableId]: string | undefined
  [yAxisDroppableId]: string | undefined
  [zAxisDroppableId]: string | undefined
}

export interface ParametersSelectionDialogProps {
  open: boolean
  onClose: () => void
  allParameterNames: string[]
  selectedParameters: SelectedParameterAxes
  setSelectedParameters: (selectedParameters: SelectedParameterAxes) => void
}

export const ParametersSelectionDialog: React.FC<
  ParametersSelectionDialogProps
> = ({
  open,
  onClose,
  allParameterNames,
  selectedParameters: initallySelectedParameters,
  setSelectedParameters: setInitiallySelectedParameters,
}) => {
  const [selectedParameters, setSelectedParameters] =
    useState<SelectedParametersState>({
      [xAxisDroppableId]: initallySelectedParameters.x,
      [yAxisDroppableId]: initallySelectedParameters.y,
      [zAxisDroppableId]: initallySelectedParameters.z,
    })
  const [draggedParameter, setDraggedParameter] = useState<string | undefined>(
    undefined
  )

  // Reset selected parameters when dialog opens
  useEffect(() => {
    if (open) {
      setSelectedParameters({
        [xAxisDroppableId]: initallySelectedParameters.x,
        [yAxisDroppableId]: initallySelectedParameters.y,
        [zAxisDroppableId]: initallySelectedParameters.z,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleDragStart = (event: DragStartEvent) => {
    setDraggedParameter(event.active.id as string)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggedParameter(undefined)

    const draggedParameter = event.active.id as string
    const destination = event.over?.id as string | undefined

    if (!destination) {
      return
    }

    const newSelectedParameters = { ...selectedParameters }

    const destinationIsSelection =
      selectedParametersDroppables.includes(destination)
    if (destinationIsSelection) {
      newSelectedParameters[destination] = draggedParameter
    }

    const source = Object.keys(selectedParameters).find(
      (key) => selectedParameters[key] === draggedParameter
    )
    if (source) {
      newSelectedParameters[source] = destinationIsSelection
        ? selectedParameters[destination]
        : undefined
    }

    setSelectedParameters(newSelectedParameters)
  }

  const unplottedParameters = allParameterNames.filter(
    (parameterName) =>
      !Object.values(selectedParameters).includes(parameterName)
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={captions_res.SELECT_PLOTTED_PARAMETERS_DIALOG_TITLE()}
      maxWidth="md"
      primaryAction={{
        label: captions_res.APPLY(),
        onClick: () => {
          setInitiallySelectedParameters({
            x: selectedParameters[xAxisDroppableId],
            y: selectedParameters[yAxisDroppableId],
            z: selectedParameters[zAxisDroppableId],
          })
          onClose()
        },
      }}
      secondaryAction={{
        label: captions_res.CANCEL(),
        onClick: onClose,
      }}
    >
      <HelpText
        text={descriptions_res.PARAMETER_SELECTION_DIALOG_HELP_TEXT()}
      />

      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        collisionDetection={pointerWithin}
      >
        <Box
          component="div"
          display="grid"
          gridTemplateColumns="auto 1fr"
          columnGap="16px"
        >
          <UnplottedParametersDroppable
            unplottedParameters={unplottedParameters}
          />

          <Box
            component="div"
            display="grid"
            gridTemplateColumns="1fr"
            rowGap="16px"
            height="min-content"
          >
            <SelectedParameterDroppable
              iconPath={mdiAxisXArrow}
              label="X Axis"
              droppableId={xAxisDroppableId}
              selectedParameter={selectedParameters[xAxisDroppableId]}
            />
            <SelectedParameterDroppable
              iconPath={mdiAxisYArrow}
              label="Y Axis"
              droppableId={yAxisDroppableId}
              selectedParameter={selectedParameters[yAxisDroppableId]}
            />
            <SelectedParameterDroppable
              iconPath={mdiAxisZArrow}
              label="Z Axis"
              droppableId={zAxisDroppableId}
              selectedParameter={selectedParameters[zAxisDroppableId]}
            />
          </Box>
        </Box>

        <DragOverlay>
          {draggedParameter && (
            <ParameterDraggablePresentation
              parameterName={draggedParameter}
              hide={false}
              grabbed={true}
            />
          )}
        </DragOverlay>
      </DndContext>
    </Dialog>
  )
}
