import React from 'react'

import { createProviderHook } from '@simphera/shared/ui-simphera'

export type EventHubCallback<TProps> = (props: TProps) => void

export interface EventHubEvent<TCallbackProps> {
  trigger: EventHubCallback<TCallbackProps>
  addListener: (listener: EventHubCallback<TCallbackProps>) => void
  removeListener: (listener: EventHubCallback<TCallbackProps>) => void
}

export const createEvent = <
  TCallbackProps,
>(): EventHubEvent<TCallbackProps> => {
  const listeners: EventHubCallback<TCallbackProps>[] = []

  return {
    trigger: (props) => {
      listeners.forEach((listener) => {
        listener(props)
      })
    },
    addListener: (listener) => {
      if (!listeners.includes(listener)) {
        listeners.push(listener)
      }
    },
    removeListener: (listener) => {
      const index = listeners.indexOf(listener)
      if (index > 0) {
        listeners.splice(index, 1)
      }
    },
  }
}

export interface EventHubProps<TEvents> {
  events: TEvents
}

/** Creates a provider and a hook that allows child components to subscribe to and trigger events within the context. */
export const createEventHubProvider = <TEvents,>() => {
  const EventHubContext = React.createContext<TEvents | undefined>(undefined)

  const EventHub: React.FC<React.PropsWithChildren<EventHubProps<TEvents>>> = ({
    events,
    children,
  }) => {
    return (
      <EventHubContext.Provider value={events}>
        {children}
      </EventHubContext.Provider>
    )
  }

  const useEventHubEvents = createProviderHook(
    EventHubContext,
    'EventHub',
    'useEventHubEvents'
  )

  return { EventHub, useEventHubEvents }
}
