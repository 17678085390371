import { colors } from '@dspace-internal/ui-kit'
import { CanvasLayer } from '@nivo/line'

import { getScaleFunctions } from '../shared/propertyDisplayUtils'

export interface TimelineRangeItem {
  start: number
  end: number
  color: string
  text?: string
}

export const timelineSegmentHeight = 18

export const createSegmentLayerComponent = (ranges: TimelineRangeItem[]) => {
  const SegmentLayer: CanvasLayer = (props) => {
    const { xScale, yScale } = getScaleFunctions(props)
    const y = yScale(0) - timelineSegmentHeight

    const canvas = props.ctx
    canvas.save()

    ranges.forEach((range) => {
      const x = xScale(range.start)
      const width = xScale(range.end) - x

      canvas.fillStyle = range.color
      canvas.fillRect(x, y, width, timelineSegmentHeight)

      if (range.text) {
        canvas.fillStyle = colors.black
        canvas.font = '12px sans-serif'
        canvas.textAlign = 'center'
        canvas.textBaseline = 'middle'
        canvas.fillText(
          range.text,
          x + width / 2,
          y + timelineSegmentHeight / 2 + 2,
          width
        )
      }
    })

    canvas.restore()
  }

  return SegmentLayer
}
