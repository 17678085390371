import { unzip } from 'unzipit'

export const unzipVisualizationData = async ({
  downloadUrlsByNodeId,
}: UnzipVisualizationDataOptions): Promise<UnzipVisualizationDataOutput> => {
  return await Promise.all(
    downloadUrlsByNodeId.map(async ({ nodeId, downloadUrl }) => {
      let visualizationJsonFile: string | undefined
      if (downloadUrl) {
        const fetchResponse = await fetch(
          downloadUrl,
          // On some customer clusters the MinIO presigned URLs do not work when code tries to download the file.
          // With this workaround the file download works but only when the user has opened MinIO in the browser once in this session.
          { credentials: 'include' }
        )
        const zipFile = await unzip(await fetchResponse.arrayBuffer())

        visualizationJsonFile =
          await zipFile.entries['visualization.json'].text()
      }

      return { nodeId, visualizationJson: visualizationJsonFile }
    })
  )
}

export interface UnzipVisualizationDataOptions {
  downloadUrlsByNodeId: { nodeId: string; downloadUrl: string }[]
}

export type UnzipVisualizationDataOutput = {
  nodeId: string
  visualizationJson: string | undefined
}[]
