import { colors, theme } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import ErrorIcon from '@mui/icons-material/Error'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'

import { Box } from '@mui/material'

export const marginOptions = {
  marginTop: 12,
  marginBottom: 6,
}

export const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 24px;
  justify-content: space-between;
`

export const NumberJobsError = styled(ErrorOutline)`
  color: ${colors.error_60};
  margin-left: 32px;
  text-align: right;
`

export const HeadingResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 16px;
`

export const SelectWrapper = styled.div`
  display: flex;
  gap: 32px;
`

export const JobCountValue = styled.span`
  text-align: right;
  min-width: 48px;
  margin-left: 8px;
`

export const StyledUnitText = styled.span`
  color: ${colors.gray_60};
  margin-right: 8px;
  flex: auto;
  padding-left: ${theme.spacing(1)};
`

// The ErrorIcon (rather than the InformationIndicator)
// is used if an error is displayed in cells, which are not in editing mode.
// Then the error is displayed as tooltip.
export const StyledCellErrorIcon = styled(ErrorIcon)`
  margin-right: ${theme.spacing(2)};
`

export const StyledCellInfoIcon = styled(InfoIcon)`
  margin-right: ${theme.spacing(2)};
`

// Content of a single data grid cell
export const StyledCell = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

// In editing mode, the muidatagrid-cell--editing style is applied by the data grid,
// which has a reduced padding.
// The input element is displayed which seem to have a default left/right-padding of 16px
// But as we want to show the error icon next to the (input) text and preserve the overall feeling
// of the input text "jumping" slightly, we induce the 16px to the enclosing Box and override the
// the default padding of input by `sx` property.
export const StyledEditCell = styled(StyledCell)`
  padding-left: 16px;
`
