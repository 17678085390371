import { SidebarItem } from '@dspace-internal/ui-kit'
import { mdiSourceBranch } from '@mdi/js'
import Icon from '@mdi/react'
import { NavigationConfig, useProjectInfo } from '@simphera/shared/state'
import {
  PATHS,
  ProjectIcon,
  ProjectListIcon,
  SidebarItemInterpolate,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { GitWorkspaceCreatePage } from './components/GitWorkspaces/GitWorkspaceCreatePage/GitWorkspaceCreatePage'
import GitWorkspaceDetails from './components/GitWorkspaces/GitWorkspaceDetails/GitWorkspaceDetails'
import { GitWorkspaceEditPage } from './components/GitWorkspaces/GitWorkspaceEditPage/GitWorkspaceEditPage'
import { loadGitWorkspace } from './components/GitWorkspaces/loaders'
import { HasCreatePermissionsGuard } from './guards/HasCreatePermissionsGuard'

const ProjectCreatePage = React.lazy(
  () => import('./components/ProjectCreatePage')
)
const ProjectImportPage = React.lazy(
  () => import('./components/ProjectImportPage')
)
const ProjectDetailPage = React.lazy(
  () => import('./components/ProjectDetailPage')
)
const ProjectEditPage = React.lazy(() => import('./components/ProjectEditPage'))
const ProjectsPage = React.lazy(() => import('./components/ProjectsPage'))

const SidebarEntries = () => {
  const projectInfo = useProjectInfo()

  return projectInfo ? (
    <>
      {projectInfo.gitWorkspace && (
        <SidebarItem
          icon={<Icon path={mdiSourceBranch} size={0.85} />}
          name="Git Workspace"
          to={PATHS.GITWORKSPACES.DETAILS(projectInfo.gitWorkspace?.id)}
          exact={true}
        />
      )}
      <SidebarItemInterpolate
        icon={<ProjectIcon />}
        name={projectInfo.name}
        to={PATHS.PROJECTS.DETAILS(projectInfo.id)}
        exact={true}
      />
    </>
  ) : (
    <SidebarItemInterpolate
      icon={<ProjectListIcon />}
      name="Projects"
      to={PATHS.PROJECTS.LIST()}
    />
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: true,
  sidebarEntries: [<SidebarEntries />],
  routes: [
    {
      path: PATHS.GITWORKSPACES.NEW(),
      element: (
        <HasCreatePermissionsGuard
          redirectTo="/projects"
          elementRenderFn={() => <GitWorkspaceCreatePage />}
        />
      ),
    },
    {
      path: PATHS.GITWORKSPACES.DETAILS(':gitWorkspaceId'),
      element: <GitWorkspaceDetails />,
    },
    {
      path: PATHS.GITWORKSPACES.EDIT(':gitWorkspaceId'),
      element: <GitWorkspaceEditPage />,
      loader: loadGitWorkspace,
    },
    {
      path: PATHS.PROJECTS.LIST(),
      element: <ProjectsPage />,
    },
    {
      path: PATHS.PROJECTS.NEW(),
      element: <ProjectCreatePage />,
    },
    {
      path: PATHS.PROJECTS.IMPORT(),
      element: <ProjectImportPage />,
    },
    {
      path: PATHS.PROJECTS.EDIT(':projectId'),
      element: <ProjectEditPage />,
    },
    {
      path: PATHS.PROJECTS.DETAILS(':projectId'),
      element: <ProjectDetailPage />,
    },
  ],
}
