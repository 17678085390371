import {
  Button,
  DataGrid,
  DataGridColumn,
  Dialog,
  Paper,
  colors,
  showSnackbar,
} from '@dspace-internal/ui-kit'
import { AddBox, Delete } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { ApiKey, ApiKeyService } from '@simphera/shared/rest-clients'
import {
  RelativeTime,
  SimpheraPageHeader,
  useSimpheraBreadcrumbs,
} from '@simphera/shared/ui-simphera'
import { isBefore } from 'date-fns'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import messages from '../../utils/messages.res'

const staticColumns: Array<DataGridColumn> = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
  },
  {
    flex: 1,
    field: 'description',
    headerName: 'Description',
  },
  {
    flex: 1,
    field: 'expirationDate',
    headerName: 'Expiration date',
    renderCell: ({ row }) => {
      const expirationDate = new Date(row.expirationDate)
      const isInThePast = isBefore(expirationDate, Date.now())

      return (
        <Box
          component="div"
          sx={{
            color: isInThePast ? colors.error_70 : undefined,
          }}
        >
          <RelativeTime date={expirationDate} />
        </Box>
      )
    },
  },
]

export const ApiKeysPage = () => {
  const [apiKeys, setApiKeys] = React.useState<Array<ApiKey>>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedApiKeyId, setSelectedApiKeyId] = React.useState<string | null>(
    null
  )

  useSimpheraBreadcrumbs()

  React.useEffect(() => {
    setIsLoading(true)

    ApiKeyService.getApiKeys()
      .then(setApiKeys)
      .catch((err) => {
        console.error(err)
        showSnackbar({
          message: messages.API_KEY_LOAD_ERROR(),
          appearance: 'error',
        })
      })
      .finally(() => setIsLoading(false))
  }, [])

  const revokeApiKey = (id: string) => {
    ApiKeyService.deleteApiKeys({ id })
      .then(() => {
        setApiKeys((keys) => keys.filter((k) => k.id !== id))
        showSnackbar({
          message: messages.API_KEY_WAS_REVOKED(),
          appearance: 'info',
        })
      })
      .catch((err) => {
        console.error(err)
        showSnackbar({
          message: messages.API_KEY_COULD_NOT_BE_REVOKED(),
          appearance: 'error',
        })
      })
  }

  const columns: Array<DataGridColumn> = [
    ...staticColumns,
    {
      flex: 0,
      minWidth: 110,
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          label="Revoke"
          size="small"
          color="primary"
          variant="outlined"
          sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}
          icon={<Delete />}
          onClick={() => setSelectedApiKeyId(row.id)}
        />
      ),
    },
  ]

  const dismissDialog = () => setSelectedApiKeyId(null)
  const acceptDialog = () => {
    revokeApiKey(selectedApiKeyId!)
    setSelectedApiKeyId(null)
  }

  return (
    <>
      <SimpheraPageHeader
        title="API keys"
        primaryAction={
          <Button
            label="Create"
            component={RouterLink}
            to="/api-keys/new"
            icon={<AddBox />}
          />
        }
      />
      <Paper>
        <Typography marginBottom={4}>
          {messages.API_KEY_DESCRIPTION()}
        </Typography>
        <DataGrid
          autoHeight
          columns={columns}
          getRowHeight={() => 'auto'}
          disableColumnPinning
          disableColumnFilter
          disableRowSelectionOnClick
          hideFooter
          loading={isLoading}
          rows={apiKeys}
          sortingOrder={['asc', 'desc']}
        />
      </Paper>
      <Dialog
        open={Boolean(selectedApiKeyId)}
        title={messages.API_KEY_REVOKE_CONFIRM()}
        primaryAction={{
          label: 'Revoke API Key',
          onClick: acceptDialog,
        }}
        secondaryAction={{
          label: 'Dismiss',
          onClick: dismissDialog,
        }}
        onClose={dismissDialog}
      >
        {messages.API_KEY_REVOKE_DESCRIPTION()}
      </Dialog>
    </>
  )
}
