import {
  SidebarGroup,
  SidebarEntries as UiKitSidebarEntries,
} from '@dspace-internal/ui-kit'
import { navigationConfig as DataReplayNavigationConfig } from '@simphera/datareplay'
import { navigationConfig as ExperimentNavigationConfig } from '@simphera/experiment'
import { navigationConfig as KpiNavigationConfig } from '@simphera/kpi'
import { navigationConfig as ProjectNavigationConfig } from '@simphera/project'
import { navigationConfig as ScbtNavigationConfig } from '@simphera/scbt'
import { navigationConfig as ScenariosNavigationConfig } from '@simphera/scenarios'
import {
  NavigationConfig,
  ProjectItemEntry,
  useProjectInfo,
} from '@simphera/shared/state'
import { navigationConfig as StepbtNavigationConfig } from '@simphera/stepbt'
import { navigationConfig as SystemundertestNavigationConfig } from '@simphera/systemundertest'
import { navigationConfig as TestenvironmentNavigationConfig } from '@simphera/testenvironment'
import { navigationConfig as VehiclesNavigationConfig } from '@simphera/vehicles'
import React from 'react'
import { withReactKeys } from '../../utils/withReactKeys'

const NAVIGATION_CONFIGS = [
  ProjectNavigationConfig,
  VehiclesNavigationConfig,
  ScenariosNavigationConfig,
  ExperimentNavigationConfig,
  SystemundertestNavigationConfig,
  KpiNavigationConfig,
  TestenvironmentNavigationConfig,
  ScbtNavigationConfig,
  StepbtNavigationConfig,
  DataReplayNavigationConfig,
].filter((cfg) => cfg.isEnabled)

export const getRoutes = () =>
  NAVIGATION_CONFIGS.map((cfg) => cfg.routes).flat()

export const getProjectLinks = (): Array<ProjectItemEntry> =>
  NAVIGATION_CONFIGS.map((cfg) => cfg.projectItemEntry!).filter(Boolean)

const getSidebarEntries = (cfg: NavigationConfig): React.ReactElement | null =>
  cfg.isEnabled ? withReactKeys(cfg.sidebarEntries) : null

export const SidebarEntries = () => {
  const projectInfo = useProjectInfo()

  return (
    <UiKitSidebarEntries>
      {getSidebarEntries(ProjectNavigationConfig)}
      {projectInfo && (
        <>
          <SidebarGroup name="Prepare">
            {getSidebarEntries(VehiclesNavigationConfig)}
            {getSidebarEntries(ScenariosNavigationConfig)}
            {getSidebarEntries(SystemundertestNavigationConfig)}
            {getSidebarEntries(TestenvironmentNavigationConfig)}
            {getSidebarEntries(KpiNavigationConfig)}
          </SidebarGroup>
          <SidebarGroup name="Simulate & Control">
            {getSidebarEntries(ExperimentNavigationConfig)}
          </SidebarGroup>
          <SidebarGroup name="Validate">
            {getSidebarEntries(ScbtNavigationConfig)}
            {getSidebarEntries(StepbtNavigationConfig)}
            {getSidebarEntries(DataReplayNavigationConfig)}
          </SidebarGroup>
        </>
      )}
    </UiKitSidebarEntries>
  )
}
