import React from 'react'

export const createProviderHook = <TContextData>(
  context: React.Context<TContextData | undefined>,
  providerName: string,
  hookName: string
) => {
  return (): TContextData => {
    const contextData = React.useContext(context)

    if (contextData === undefined) {
      // Hook was called outside of its provider
      // => Throw an error to notify the developer
      throw new Error(
        `The ${hookName}() hook must only be called within a ${providerName}.`
      )
    }

    return contextData
  }
}
