import React from 'react'

import {
  KpiVisualizationProperty,
  StringTimelineProperty,
} from '../KpiVisualiziationTypes'
import { chartColorPalette } from '../propertyDisplayUtils'

export interface StringTimelineColorData {
  colorMapping: Record<string, string>
}

const StringTimelineColorContext = React.createContext<
  StringTimelineColorData | undefined
>(undefined)

export const useStringTimelineColors = (): StringTimelineColorData => {
  const context = React.useContext(StringTimelineColorContext)

  if (context === undefined) {
    // useStringTimelineColors was called outside of a StringTimelineColorProvider
    // => Throw an error to notify the developer
    throw new Error(
      'The useStringTimelineColors() hook must only be called within a StringTimelineColorProvider.'
    )
  }

  return context
}

export interface StringTimelineColorProviderProps {
  properties: KpiVisualizationProperty[]
}

const StringTimelineColorProvider: React.FC<
  React.PropsWithChildren<StringTimelineColorProviderProps>
> = ({ properties, children }) => {
  const providerData = React.useMemo<StringTimelineColorData>(() => {
    const colorMapping: Record<string, string> = {}

    new Set(
      properties
        .filter((property) => property.type === 'StringTimeline')
        .flatMap((property) =>
          (property as StringTimelineProperty).data.map((range) => range.value)
        )
    ).forEach((value) => {
      const colorIndex =
        Object.keys(colorMapping).length % chartColorPalette.length
      colorMapping[value] = chartColorPalette[colorIndex]
    })

    return { colorMapping }
  }, [properties])

  return (
    <StringTimelineColorContext.Provider value={providerData}>
      {children}
    </StringTimelineColorContext.Provider>
  )
}

export default StringTimelineColorProvider
