import { TextField } from '@dspace-internal/ui-kit'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../../utils/messages.res'
import { FILE_PATH_CHAR_LIMIT } from './constants'
import { IReadOnly, IRequired, ISize } from './types'

export const PathToProjectYamlInput: React.FC<
  IReadOnly & IRequired & ISize & { helperText?: string }
> = ({
  isReadOnly = false,
  isRequired = false,
  size = 'small',
  helperText = 'Provide a path relative to the repository URL',
}) => {
  const { control, formState } =
    useFormContext<Required<{ pathToProjectYaml: string }>>()
  const error = formState.errors.pathToProjectYaml

  return (
    <Controller
      control={control}
      name="pathToProjectYaml"
      defaultValue=""
      rules={{
        required: isRequired
          ? messages.INPUT_CANNOT_BE_EMPTY('project YAML file path')
          : false,
        validate: {
          isValidLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(FILE_PATH_CHAR_LIMIT),
            FILE_PATH_CHAR_LIMIT
          ),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          error={Boolean(error)}
          fullWidth
          helperText={error ? error.message : helperText}
          label="Project YAML file path"
          name="projectYamlFilePath"
          autoComplete="off"
          size={size}
          placeholder="path/to/project.yaml"
          disabled={isReadOnly}
          InputLabelProps={{ shrink: true }}
          required={isRequired}
        />
      )}
    />
  )
}
