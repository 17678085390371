import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import Icon from '@mdi/react'

interface DroppableContainerProps {
  isHovered: boolean
}

export const DroppableContainer = styled.div<DroppableContainerProps>`
  background-color: ${(props) =>
    props.isHovered ? colors.blue_20 : colors.gray_20};
  border: 1px dashed ${colors.gray_70};
  border-radius: 4px;
  padding: 12px;

  // It's very easy to accidentally select text while trying to grab something so we disable it
  user-select: none;
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 4px;

  color: ${colors.gray_90};

  font-size: 16px;

  span {
    margin-left: 4px;
  }
`

export interface DroppableContainerHeadingProps {
  iconPath: string
  label: string
}

export const DroppableContainerHeading: React.FC<
  DroppableContainerHeadingProps
> = ({ iconPath, label }) => {
  return (
    <HeadingWrapper>
      <Icon path={iconPath} size={0.8} />
      <span>{label}</span>
    </HeadingWrapper>
  )
}
