import {
  BreadcrumbConfig,
  configureBreadcrumb,
  setBreadcrumbs,
} from '@dspace-internal/ui-kit'
import { useProjectInfo } from '@simphera/shared/state'
import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { PATHS } from './paths'

/** use this to update breadcrumbs. it will take care of setting the HOME, GIT_WORKSPACE and PROJECT breadcrumbs automatically */
export const useSimpheraBreadcrumbs = (
  breadcrumbs: Array<BreadcrumbConfig> = []
) => {
  const projectInfo = useProjectInfo()
  const isOnHomePage = useMatch({
    path: PATHS.PROJECTS.LIST(),
    end: true,
  })
  const isOnProjectPage = useMatch({
    path: PATHS.PROJECTS.DETAILS(':projectId'),
    end: true,
  })

  useEffect(() => {
    const prefix = []

    if (!isOnHomePage) {
      prefix.push(configureBreadcrumb('Home', PATHS.PROJECTS.LIST()))
    }

    if (projectInfo && projectInfo.gitWorkspace) {
      prefix.push(
        configureBreadcrumb(
          projectInfo.gitWorkspace.name,
          PATHS.GITWORKSPACES.DETAILS(projectInfo.gitWorkspace.id)
        )
      )
    }

    if (projectInfo && !isOnProjectPage) {
      prefix.push(
        configureBreadcrumb(
          projectInfo?.name,
          PATHS.PROJECTS.DETAILS(projectInfo?.id)
        )
      )
    }

    setBreadcrumbs([...prefix, ...breadcrumbs])

    return () => setBreadcrumbs([])
  }, [breadcrumbs, isOnHomePage, isOnProjectPage, projectInfo])
}
