import { fmt } from './messageUtils'

export const messages_res = {
  PROMPT_UNSAVED_CHANGES: () =>
    fmt(
      'This page contains unsaved changes. Do you really want to leave this page? All changes will be discarded.'
    ),
  FAILED_SAVE_SCENARIO_FILES_NODATA: () =>
    fmt('Could not save the scenario. Did not receive any data'),
  FAILED_SAVE_SCENARIO_FILES: (scenarioName: string) =>
    fmt(`Could not save the scenario: {scenarioName}`, { scenarioName }),
  SAVED_SCENARIO: () => fmt('Scenario saved.'),
  SAVING_SCENARIO: () => fmt('Saving...'),
  DELETING_SCENARIO: () => fmt('Deleting...'),
  FILE_OF_SAME_TYPE_ERROR: (sameType: string) =>
    fmt(`Cannot add two files of the same type: {sameType}`, { sameType }),
  FILE_OF_SAME_TYPE_ADDED_ERROR: (sameType: string) =>
    fmt(`You already added a file of the following type: {sameType}`, {
      sameType,
    }),
  FAILED_LOAD_VIDEO: () => fmt('Could not load the video.'),
  FAILED_DELETE_SCENARIO_NODATA: () =>
    fmt('Could not delete the scenario. Did not receive any data.'),
  FAILED_DELETE_SCENARIO: () => fmt('Could not delete the scenario.'),
  DELETE_SCENARIO_SUCCESS: () => fmt('Scenario deleted.'),
  IMPORT_SCENARIO_NO_DATA_ERROR: () =>
    fmt('Could not import the scenario: Did not receive any data.'),
  IMPORT_SCENARIO_ERROR: () => fmt('Could not import the scenario.'),
  IMPORT_SCENARIO_NO_SCENARIO_ERROR: () =>
    fmt(`Could not import the scenario. Did not receive a scenario.`),
  IMPORTING_SCENARIO: () => fmt('Importing scenarios...'),
  IMPORT_SCENARIO_INFO: () => fmt('Imported all selected scenarios.'),
  UNSAVED_CHANGES: () =>
    fmt(
      'The scenario contains unsaved changes. Are you sure you want to leave? All changes will be discarded.'
    ),
  PARSING_ZIP: () => fmt('Parsing ZIP archive...'),
  NOT_XML_ERROR: (fileName: string) =>
    fmt('{fileName} is not a xml format', { fileName }),
  FILE_VERSION_ERROR: (name: string, fileVersion: string | null | undefined) =>
    fmt('File version does not match {name} {fileVersion}', {
      name,
      fileVersion,
    }),
  IMPORTING_FILE_VERSION_ERROR: () =>
    fmt('Some files do not match the expected file version.'),
  ERROR_PARSING_SCENARIO_POOL_ZIP_UNKNOWN_ERROR: () =>
    fmt('Could not parse the Scenario Pool ZIP file.'),
  ERROR_PARSING_SCENARIO_POOL_ZIP: (errMessage: string | undefined) => {
    if (errMessage) {
      return fmt(
        'Could not parse the Scenario Pool ZIP file. Error: {errMessage}',
        {
          errMessage,
        }
      )
    } else {
      return fmt('Could not parse the Scenario Pool ZIP file.')
    }
  },
  FILE_TOO_LARGE_ERROR: (maxSize: number, unit: string) =>
    fmt('File is larger than {maxSize} {unit}, please select a smaller one.', {
      maxSize,
      unit,
    }),
  INVALID_FILES_SELECTED: (amount: number) =>
    fmt(
      `Invalid {amount, plural,
        =1 {# file}
        other {# files}
      }! selected.`,
      { amount }
    ),
  INVALID_FILE_SELECTED: (name: string) =>
    fmt('Invalid file selected: {name}', { name }),
  NO_3D_ENVIRONMENT: () =>
    fmt('No 3D environment available. Upload or generate one.'),
  NO_3D_ENVIRONMENT_CONTENT: () =>
    fmt('The environment file does not contain any usable models.'),
  ERROR_PARSE_ENV_ZIP: () => fmt('Could not parse the ZIP file.'),
  ERROR_LOAD_ENV_ZIP: () => fmt('Could not load the ZIP file.'),
  ERROR_GENERATE_3D_ENVIRONMENT: () =>
    fmt('Could not generate the 3D environment.'),
  ERROR_GENERATE_ROAD_PREVIEW: () =>
    fmt('Could not generate the road preview.'),
  ERROR_LOAD_ROAD_PREVIEW: () => fmt('Could not load the road preview.'),
  SUCCESS_GENERATE_3D_ENVIRONMENT: () => fmt('Generated the 3D environment.'),
  SELECT_3D_ENVIRONMENT: () =>
    fmt('Select the 3D environment to be used in AURELION.'),
  OVERWRITE_SCENERY_WARNING: () =>
    fmt(
      'A 3D environment already exists. Do you want to generate a new environment from the road?'
    ),
  ERROR_MISSING_ZIP_FILE: () => fmt('Did not receive a ZIP file.'),
  ERROR_CONVERT_BASSE64_TO_FILE: () =>
    fmt('Could not convert a base64 string to a file.'),
  NO_ROAD_PREVIEW: () =>
    fmt('A road preview for this scenario is not available.'),
  SAVING_UPLOAD_SCENARIO: () => fmt('Saving and uploading files...'),
  NETWORK_REQUEST_FAILED: () => fmt('Network request failed'),
  NETWORK_REQUEST_TIMEOUT: () => fmt('Network request timeout'),
  INVALID_OPTIONS: () => fmt('Invalid options'),
  ERROR_PERMISSION_DENIED: () => fmt('Permission denied'),
  SCENARIOS_READONLY: () => fmt(`No scenarios available`),
  SCENARIOS_NO_PERMISSIONS: () =>
    fmt(`You do not have permission to create items in this project.`),
  ITEM_NOT_FOUND: (itemType: string, itemId: string) =>
    fmt(`{itemType} with ID "{itemId}" not found.`, { itemType, itemId }),
  ITEM_NOT_FOUND_DETAILS: () => fmt(`The item does not exist.`),
}
