import { showSnackbar } from '@dspace-internal/ui-kit'
import {
  JsonPatchOperation,
  KpisService,
  useOpenApiMutation,
  useOpenApiQuery,
} from '@simphera/shared/rest-clients'
import { useProjectInfo } from '@simphera/shared/state'
import {
  PageWithQueryLayout,
  ProjectIdParams,
  usePageParams,
  useSimpheraBreadcrumbs,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { BREADCRUMBS } from '../../breadcrumbs'
import { messages_res } from '../../resources/messages.res'
import { KpiForm, OnSaveKpiCallback } from './KpiForm'

export type KpiDetailsPageParams = ProjectIdParams & {
  kpiId: string
}

export const KpiDetailsPage: React.FC = () => {
  const { projectId, kpiId } = usePageParams<KpiDetailsPageParams>()

  const { data, isLoadingInitialData, error, refetch } = useOpenApiQuery(
    KpisService.getKpis1,
    {
      params: {
        projectId,
        kpiId,
      },
    }
  )

  useSimpheraBreadcrumbs([BREADCRUMBS.LIST(projectId)])

  const { mutate } = useOpenApiMutation(KpisService.patchKpis, {
    onSuccess: () => {
      showSnackbar({
        appearance: 'info',
        message: messages_res.KPI_UPDATED(),
      })
      refetch()
    },
    onError: (errorMessage) => {
      showSnackbar({
        appearance: 'error',
        message: messages_res.KPI_UPDATE_FAILED(errorMessage),
      })
    },
  })

  const handleSave: OnSaveKpiCallback = (changedFields) => {
    const patch: JsonPatchOperation[] = []
    if (changedFields.name !== undefined) {
      patch.push({ op: 'add', path: 'name', value: changedFields.name })
    }
    if (changedFields.description !== undefined) {
      patch.push({
        op: 'add',
        path: 'description',
        value: changedFields.description,
      })
    }

    mutate({ kpiId, projectId, requestBody: patch })
  }

  const projectInfo = useProjectInfo()

  return (
    <PageWithQueryLayout
      // Wait for permissions to load too
      loading={isLoadingInitialData || !projectInfo?.projectPermissions}
      error={error}
    >
      {() => <KpiForm initialValue={data!} onSave={handleSave} />}
    </PageWithQueryLayout>
  )
}
