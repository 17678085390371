import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/stepbt'

import { applyClientConfig } from '../clientConfig'

applyClientConfig(OpenAPI, { baseUrl: GlobalConfig.stepbt.REST_API_URL })

export {
  RunsService,
  type RunDTO,
  type RunDTOPaginatedCollectionResponse,
  type UserDto,
} from '../../generated/stepbt'

export type StepbtManagerApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
