import React from 'react'
import { createProviderHook } from '../../../utils'
import { DashboardWidgets } from '../types'

export interface InternalDashboardContextData {
  widgets: DashboardWidgets
}

const InternalDashboardContext = React.createContext<
  InternalDashboardContextData | undefined
>(undefined)

export interface InternalDashboardContextProviderProps {
  widgets: DashboardWidgets
}

/**
 * Internally provides data to panels within the dashboard.
 */
export const InternalDashboardContextProvider: React.FC<
  React.PropsWithChildren<InternalDashboardContextProviderProps>
> = ({ children, widgets }) => {
  return (
    <InternalDashboardContext.Provider value={{ widgets }}>
      {children}
    </InternalDashboardContext.Provider>
  )
}

const useInternalDashboardData = createProviderHook(
  InternalDashboardContext,
  'InternalDashboardContextProvider',
  'useInternalDashboardData'
)

export const useDashboardWidget = (widgetId: string) => {
  const { widgets } = useInternalDashboardData()

  return widgets[widgetId]
}
