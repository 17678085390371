import React from 'react'

import { createProviderHook } from '@simphera/shared/ui-simphera'
import { messages_res } from '../../../resources/messages.res'
import {
  KpiVisualizationProperty,
  ReferenceProperty,
  getAllProperties,
  isProperty,
  parseKpiVisualizationJson,
} from '../PropertyDisplays/KpiVisualiziationTypes'
import {
  UnzipVisualizationDataOutput,
  unzipVisualizationData,
} from '../unzipVisualizationData'
import VideoPropertyLoader, {
  GetArtifactDownloadUrlsSignature,
} from './VideoPropertyLoader'

export interface ReferencedTargetProperty {
  property: KpiVisualizationProperty | undefined
  notFoundMessage: string | undefined
}

export interface PropertyReferencesContextData {
  loadingExternalProperties: boolean
  getReferencedProperty: (
    referenceProperty: ReferenceProperty
  ) => ReferencedTargetProperty
}

const PropertyReferencesContext = React.createContext<
  PropertyReferencesContextData | undefined
>(undefined)

export const usePropertyReferences = createProviderHook(
  PropertyReferencesContext,
  'PropertyLoader',
  'usePropertyReferences'
)

export type UseGetVisualizationDataDownloadUrlsSignature = (
  nodeIds: string[]
) => {
  loading: boolean
  data: { nodeId: string; downloadUrl: string }[] | undefined
}

export interface PropertyLoaderProps {
  properties: KpiVisualizationProperty[]
  nodeId: string
  useGetVisualizationDataDownloadUrls: UseGetVisualizationDataDownloadUrlsSignature
  getArtifactDownloadUrls: GetArtifactDownloadUrlsSignature
}

/**
 * Loads data for visualization properties that require additionally querried data.
 */

const PropertyLoader: React.FC<
  React.PropsWithChildren<PropertyLoaderProps>
> = ({
  properties,
  nodeId,
  useGetVisualizationDataDownloadUrls,
  getArtifactDownloadUrls,
  children,
}) => {
  const [jsonDataByNodeId, setJsonDataByNodeId] = React.useState<
    UnzipVisualizationDataOutput | undefined
  >(undefined)
  const uzipProcessStarted = React.useRef<boolean>(false)
  const loading = !jsonDataByNodeId

  const requiredNodeIds = React.useMemo(
    () => [
      ...new Set(
        getAllProperties(properties)
          .filter(
            (property): property is ReferenceProperty =>
              property.type === 'Reference'
          )
          .map((property) => property.processingObjectId)
      ),
    ],
    [properties]
  )

  const { data, loading: loadingDownloadUrls } =
    useGetVisualizationDataDownloadUrls(requiredNodeIds)

  React.useEffect(() => {
    if (!loadingDownloadUrls && data && !uzipProcessStarted.current) {
      uzipProcessStarted.current = true
      unzipVisualizationData({ downloadUrlsByNodeId: data }).then((result) => {
        setJsonDataByNodeId(result)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const externalProperties = React.useMemo(() => {
    if (!jsonDataByNodeId) {
      return undefined
    }

    const externalProperties: Record<string, KpiVisualizationProperty[]> = {}
    jsonDataByNodeId.forEach((visualizationJson) => {
      if (visualizationJson.visualizationJson) {
        externalProperties[visualizationJson.nodeId] = getAllProperties(
          parseKpiVisualizationJson(visualizationJson.visualizationJson)
            .properties
        )
      }
    })

    return externalProperties
  }, [jsonDataByNodeId])

  const { propertiesByNodeId, getReferencedProperty } = React.useMemo(() => {
    const propertiesByNodeId: Record<string, KpiVisualizationProperty[]> = {}
    propertiesByNodeId[nodeId] = properties

    const allProperties = getAllProperties(properties)

    const getReferencedProperty = (
      referenceProperty: ReferenceProperty
    ): ReferencedTargetProperty => {
      let nodeProperties: KpiVisualizationProperty[]

      if (referenceProperty.processingObjectId === nodeId) {
        nodeProperties = allProperties.filter(
          (property) => property.type !== 'Reference'
        )
      } else {
        if (!externalProperties) {
          // This case should never happen because getReferenceProperty is only called after externalProperties has been loaded
          return { property: undefined, notFoundMessage: undefined } as never
        }

        nodeProperties =
          externalProperties[referenceProperty.processingObjectId]
      }

      if (!nodeProperties) {
        return {
          property: undefined,
          notFoundMessage: messages_res.KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE(
            referenceProperty.processingObjectId,
            referenceProperty.targetPropertyId,
            messages_res.KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE_NODE_NOT_FOUND()
          ),
        }
      }

      const targetProperty = nodeProperties.find(
        (externalProperty) =>
          externalProperty.id === referenceProperty.targetPropertyId
      )

      if (targetProperty) {
        return { property: targetProperty, notFoundMessage: undefined }
      } else {
        return {
          property: undefined,
          notFoundMessage: messages_res.KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE(
            referenceProperty.processingObjectId,
            referenceProperty.targetPropertyId,
            messages_res.KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE_PROPERTY_NOT_FOUND()
          ),
        }
      }
    }

    if (externalProperties) {
      // Add actually used external properties to load their required additional data
      allProperties
        .filter(isProperty<ReferenceProperty>('Reference'))
        .forEach((property) => {
          const { property: targetProperty } = getReferencedProperty(property)
          if (targetProperty) {
            if (!(property.processingObjectId in propertiesByNodeId)) {
              propertiesByNodeId[property.processingObjectId] = []
            }
            propertiesByNodeId[property.processingObjectId].push(targetProperty)
          }
        })
    }

    return { propertiesByNodeId, getReferencedProperty }
  }, [nodeId, properties, externalProperties])

  return (
    <PropertyReferencesContext.Provider
      value={{ loadingExternalProperties: loading, getReferencedProperty }}
    >
      <VideoPropertyLoader
        propertiesByNodeId={propertiesByNodeId}
        loadingProperties={loading}
        getArtifactDownloadUrls={getArtifactDownloadUrls}
      >
        {children}
      </VideoPropertyLoader>
    </PropertyReferencesContext.Provider>
  )
}

export default PropertyLoader
