import { fmt } from './messageUtils'

export const captions_res = {
  ADD_PARAMETER: () => fmt(`Add Parameter`),
  DELETE_PARAMETER: () => fmt(`Delete Parameter`),
  ADD_JOB: () => fmt(`ADD JOB`),
  DELETE: () => fmt(`Delete`),
  NO_DATA: () => fmt(`No Data`),
  NAME: () => fmt(`Name`),
  VALUE: () => fmt(`Value`),
  FIXED: () => fmt(`Fixed`),
  START: () => fmt(`Start`),
  STOP: () => fmt(`Stop`),
  STEP_SIZE: () => fmt(`Step Size`),
  VARIATION: () => fmt(`Variation`),
  SEARCH: () => fmt(`Search`),
  NAME_ASCENDING: () => fmt(`Name: Ascending`),
  NAME_DESCENDING: () => fmt(`Name: Descending`),
  SUITE_NAME: () => fmt(`Suite`),
  SUT_NAME: () => fmt(`SUT`),
  TESTENVIRONMENT_NAME: () => fmt(`Test Environment`),
  SELECT_PLOTTED_PARAMETERS_DIALOG_TITLE: () =>
    fmt(`Select Plotted Parameters`),
  PARAMETER_SELECTION_DROPZONE: () => fmt(`Drag and drop parameter here`),
  SELECT_PLOTTED_PARAMETERS_BUTTON_TEXT: () => fmt(`Select Plotted Parameters`),
  CANCEL: () => fmt(`Cancel`),
  APPLY: () => fmt(`APPLY`),
}
