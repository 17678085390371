import { colors, theme } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

export const PanelFrame = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0, 1fr);
`

export const PanelHeader = styled.div`
  width: 100%;
  overflow: hidden;

  background-color: ${colors.white};
  padding: 8px;

  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: 1fr;

  cursor: grab;
`

export const PanelHeaderName = styled.div`
  margin-left: 8px;

  width: 100%;
  height: 24px;
  line-height: 24px;
`

export const PanelContent = styled.div`
  width: 100%;
  height: 100%;

  // Min width must be set to 0 or panel content won't shrink correctly
  min-width: 0;
`

export const PanelHeaderDragPreview = styled(PanelHeader)`
  width: fit-content;
  max-width: 256px;
  box-shadow: ${theme.shadows[1]};

  cursor: grabbing;
`
