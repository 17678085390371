import {
  AppLayout,
  ContentContainer,
  ContentLayout,
  DSpaceCompactLogo,
  DSpaceSimpheraLogo,
  Sidebar,
  SidebarLogo,
  SnackbarRenderer,
  Spinner,
} from '@dspace-internal/ui-kit'
import {
  ProjectProvider,
  setProjectLinks,
  useProjectInfo,
} from '@simphera/shared/state'
import React, { PropsWithChildren, Suspense } from 'react'
import { Header } from '../Header/Header'
import { SidebarEntries, getProjectLinks } from './navigationConfigs'

export const AppFrame: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ProjectProvider>
    <AppFrameWithProjectAccess>{children}</AppFrameWithProjectAccess>
  </ProjectProvider>
)

const AppFrameWithProjectAccess: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const projectInfo = useProjectInfo()

  React.useEffect(() => {
    setProjectLinks(getProjectLinks())
  }, [projectInfo])

  return (
    <AppLayout>
      <Sidebar>
        <SidebarLogo
          logo={<DSpaceSimpheraLogo />}
          compactLogo={<DSpaceCompactLogo />}
          applicationName="dSPACE Simphera"
        />
        <SidebarEntries />
      </Sidebar>
      <ContentLayout>
        <Header />
        <ContentContainer>
          <Suspense fallback={<Spinner />}>{children}</Suspense>
        </ContentContainer>
      </ContentLayout>
      <SnackbarRenderer />
    </AppLayout>
  )
}
