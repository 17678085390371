/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Operation,
} from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'
import { traceparent } from 'tctx'

import { API_GATEWAY_HOST } from '../config'

// Instantiate logout fn and token with initial values
let logoutFn = () => {
  console.warn('No logout function set')
}

let accessToken = ''

async function setAuthHeader() {
  const traceId = traceparent.make().toString()
  return {
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'x-request-id': traceId,
      traceparent: traceId,
    },
  }
}

const link = createHttpLink({
  uri: API_GATEWAY_HOST(),
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  checkErrors(graphQLErrors, networkError, operation)
})

const checkErrors = (
  graphQLErrors: readonly GraphQLError[] | undefined,
  networkError: NetworkError | undefined,
  operation: Operation
): void => {
  // operation context contains status code
  if (operation.getContext().response.status === 401) {
    if (networkError) {
      // override parse error with auth failed message
      networkError.message = 'Authentication failed!'
      networkError.name = 'Authentication Error'
    }
    logoutFn()
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
}

const authLink = setContext(async (_, { headers }) => {
  return setAuthHeader()
})

// Function to update token and logout function from outside of this file
export const apolloClientUpdate = (token: string, logout: () => void) => {
  logoutFn = logout
  accessToken = token
}

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      // Disable those key fields because the identifier is not unique
      // which means caching issues with the snapshots.
      SystemUnderTest: {
        keyFields: false,
      },
      TestEnvironment: {
        keyFields: false,
      },
    },
  }),
  link: ApolloLink.from([errorLink, authLink.concat(link)]),
})
