import IntlMessageFormat from 'intl-messageformat'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fmt = (message: string, ...rest: any[]): string => {
  const formatter = new IntlMessageFormat(message, 'en-US')
  const formatted = formatter.format(...rest) as string
  return formatted
}

const messages = {
  CREATE_OPTIONS: () => 'Create Options',
  CREATE_PROJECT_TITLE: () => 'Empty Project',
  CREATE_PROJECT_DESCRIPTION: () => 'Create an empty Project.',
  IMPORT_PROJECT_TITLE: () => 'Project from ZIP File',
  IMPORT_PROJECT_DESCRIPTION: () =>
    'Create a Project based upon the content of the ZIP file.',
  CREATE_GIT_WORKSPACE_TITLE: () => 'Empty Git Workspace',
  CREATE_GIT_WORKSPACE_DESCRIPTION: () =>
    'Create a Git Workspace with a connection to a Git repository. Use this for Git-based workflows.',
  UNKNOWN_REASON: () => 'Unknown reason',
  INVALID_FILE_FORMAT: () => 'Invalid file format',
  IMPORT_PROJECT_PAGE_HEADER_TITLE: () => 'Import Project',
  IMPORT_PROJECT_CTA: () => 'Provide a ZIP file containing a SIMPHERA project',
  NO_PERMISSIONS_TO_VIEW_PAGE: () =>
    `You don't have the required permissions to view this page`,
  INFO_UNSAVED_CHANGES: (): string =>
    fmt(
      `\nThere are unsaved modifications on this page. Close and lose changes?`
    ),
  CREATE_NEW_EMPTY_STATE: () => fmt('Create a new project to start working.'),
  PROJECTS_PAGE_EMPTY_NO_PERMISSION: () =>
    fmt(
      'Currently you cannot see any projects. Please contact your administrator.'
    ),
  NO_CREATE_PERMISSION: () => fmt('No create permission'),
  NO_CREATE_PERMISSION_TOOLTIP: () =>
    fmt('You do not have the permission to create projects'),
  GIT_WORKSPACE_FAILED_LOADING: (message: string) =>
    `Failed loading the Git Workspace: ${message}`,
  GIT_WORKSPACE_COMMITS_FAILED_LOADING: (message: string) =>
    `Failed loading the commits of the Git Workspace: ${message}`,
  GIT_WORKSPACE_UPDATE_ERROR: (message: string) =>
    `There was an error while updating the Git Workspace: ${message}`,
  GIT_WORKSPACE_CREATE_ERROR: (message: string) =>
    `There was an error while creating the Git Workspace: ${message}`,
  GIT_WORKSPACE_LOAD_ERROR: () =>
    `Git Workspace could not be loaded. Please try again.`,
  UNSAVED_CHANGES: () =>
    `This page contains unsaved changes. Do you really want to leave this page? All changes will be discarded.`,
  GIT_WORKSPACE_UPDATED: () => `The Git Workspace was updated.`,
  GIT_WORKSPACE_CREATED: () => `The Git Workspace was created.`,
  SAVING: () => fmt(`Saving...`),
  INPUT_CANNOT_BE_EMPTY: (fieldName: string) =>
    `The ${fieldName} cannot be empty.`,
  INVALID_URL: () => `The entered URL is invalid.`,
  MAXIMUM_CHARACTERS: (length: number) =>
    `A maximum of ${length} characters is allowed.`,
  IMPORT_OPERATION_STARTED: (operationId: string) =>
    `The import started successfully. (Operation ID: ${operationId})`,
  IMPORT_OPERATION_FAILED: (errorMessage: string) =>
    `The import failed with the reason: ${errorMessage}`,
  INCORRECT_FORMAT: (correctExample: string) =>
    `The format you have entered is not correct. The correct format is ${correctExample}`,
  GIT_IMPORT_CONFIGURATION_SOURCE: () =>
    `This is taken from the default Git-Workspace configuration. This can be changed, if needed.`,
}

export default messages
