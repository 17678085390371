import { useApolloClient } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { getProjectAndWorkspaceInfo } from './getProjectAndWorkspaceInfo'
import { ProjectInfo } from './types'

const ProjectContext = React.createContext<ProjectInfo | null>(null)

export const ProjectProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const client = useApolloClient()
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null)
  const navigate = useNavigate()

  const matchProjectsPage = useMatch({ path: '/projects', caseSensitive: true })
  const matchGitWorkspacePage = useMatch({
    path: '/gitWorkspaces/*',
    caseSensitive: true,
  })
  const matchOutsideProject = matchProjectsPage || matchGitWorkspacePage
  const matchProjectPage = useMatch({
    path: '/projects/:projectId/*',
    caseSensitive: false,
  })

  useEffect(() => {
    const queryProjectData = async () => {
      if (!matchProjectPage) {
        return
      }

      const { projectId } = matchProjectPage.params

      if (projectId === 'new' || projectId === 'import') {
        setProjectInfo(null)
        return
      }

      try {
        const projectInfo = await getProjectAndWorkspaceInfo(
          projectId!,
          client,
          queryProjectData
        )

        setProjectInfo(projectInfo)
      } catch (error: unknown) {
        navigate('/projects')
      }
    }

    // reset projectInfo whenever not on project page
    if (!matchProjectPage && projectInfo) {
      setProjectInfo(null)
    }

    if (matchOutsideProject) {
      setProjectInfo(null)
    }

    if (!projectInfo) {
      queryProjectData()
    }
  }, [matchProjectPage, projectInfo, navigate, matchOutsideProject, client])

  return (
    <ProjectContext.Provider value={projectInfo}>
      {children}
    </ProjectContext.Provider>
  )
}

export const useProjectInfo = () => useContext(ProjectContext)
