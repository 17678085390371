import { colors } from '@dspace-internal/ui-kit'
import { BarCanvasProps } from '@nivo/bar'
import { Margin } from '@nivo/core'

export const chartColorPalette = [
  colors.chart_1,
  colors.chart_3,
  colors.chart_5,
  colors.chart_7,
  colors.chart_9,
  colors.chart_11,
  colors.chart_12,
  colors.chart_2,
  colors.chart_4,
  colors.chart_6,
  colors.chart_8,
  colors.chart_10,
]

interface GetAxisChartPropsOptions {
  xAxisLabel: string | undefined
  xAxisUnit: string | undefined
  yAxisLabel: string | undefined
  yAxisUnit: string | undefined
}

const getAxisLabel = (label: string | undefined, unit: string | undefined) => {
  if (!label) {
    return undefined
  } else if (!unit) {
    return label
  } else {
    return `${label} [${unit}]`
  }
}

export const horizontalChartMargin: Pick<Margin, 'left' | 'right'> = {
  left: 48,
  right: 8,
}

export const getAxisChartProps = ({
  xAxisLabel,
  xAxisUnit,
  yAxisLabel,
  yAxisUnit,
}: GetAxisChartPropsOptions) => {
  const margin: Margin = {
    ...horizontalChartMargin,
    bottom: 48,
    top: 8,
  }

  const axisBottom: BarCanvasProps<any>['axisBottom'] = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: getAxisLabel(xAxisLabel, xAxisUnit),
    legendOffset: 36,
    legendPosition: 'middle',
  }
  const axisLeft: BarCanvasProps<any>['axisLeft'] = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: getAxisLabel(yAxisLabel, yAxisUnit),
    legendOffset: -40,
    legendPosition: 'middle',
  }

  return {
    margin,
    axisBottom,
    axisLeft,
  }
}
