import { MAPPED_GLOBAL_VARIABLES } from './mappedGlobalVariables'
import {
  AdditionalLink,
  AppConfig,
  CoreServiceConfig,
  DatareplayConfig,
  ExperimentConfig,
  FeatureFlags,
  KpiConfig,
  ProjectConfig,
  ScbtConfig,
  ScenariosConfig,
  StepbtConfig,
  SystemundertestConfig,
  TestenvironmentConfig,
  VehicleConfig,
} from './typings'

/**
 * the functionality in this class will only run once
 * it copies the global state from the window object to static class properties for easy access
 * after this is done, it removes the global state from the window object
 */
export class GlobalConfig {
  static appConfig: AppConfig
  static featureFlags: FeatureFlags
  static additionalLinks: Array<AdditionalLink>
  static project: ProjectConfig
  static scbt: ScbtConfig
  static scenarios: ScenariosConfig
  static vehicle: VehicleConfig
  static stepbt: StepbtConfig
  static datareplay: DatareplayConfig
  static experiment: ExperimentConfig
  static systemundertest: SystemundertestConfig
  static kpi: KpiConfig
  static testenvironment: TestenvironmentConfig
  static coreService: CoreServiceConfig

  constructor() {
    if (GlobalConfig.appConfig) {
      // aborting here to prevent running this twice
      return
    }

    this.processAdditionalLinks()
    this.processRest()
  }

  private processAdditionalLinks() {
    // additional links get a special treatment, because they are an array
    GlobalConfig.additionalLinks = [...(window.__ADDITIONAL_LINKS__ || [])]

    delete (window as any).__ADDITIONAL_LINKS__
  }

  private processRest() {
    // all other globals are objects...
    MAPPED_GLOBAL_VARIABLES.forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      GlobalConfig[item.localVar] = {
        ...((window as any)[item.globalVar] || {}),
      }

      delete (window as any)[item.globalVar]
    })
  }
}

new GlobalConfig()
