import { Tooltip } from '@dspace-internal/ui-kit'
import SettingsIcon from '@mui/icons-material/Settings'
import { Badge, BadgeProps, Box, IconButton, styled } from '@mui/material'
import { useState } from 'react'
import { captions_res } from '../../../../resources/captions'
import {
  ParametersSelectionDialog,
  SelectedParameterAxes,
} from './ParametersSelectionDialog'

const SettingsButtonBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 4,
    top: 8,
    pointerEvents: 'none',
  },
}))

export interface ParameterSelectionDialogButtonProps {
  parameterNames: string[]
  selectedParameters: SelectedParameterAxes
  onSelectedParametersChanged: (
    selectedParameters: SelectedParameterAxes
  ) => void
}

export const ParameterSelectionDialogButton: React.FC<
  ParameterSelectionDialogButtonProps
> = ({ parameterNames, selectedParameters, onSelectedParametersChanged }) => {
  const [showSelectionDialog, setShowSelectionDialog] = useState(false)
  const [showSelectionDialogHint, setShowSelectionDialogHint] = useState(
    parameterNames.length > 3
  )

  return (
    <>
      <Tooltip message={captions_res.SELECT_PLOTTED_PARAMETERS_BUTTON_TEXT()}>
        <Box component="div" position="absolute" bottom={8} right={8}>
          <SettingsButtonBadge
            color="info"
            variant="dot"
            invisible={!showSelectionDialogHint}
          >
            <IconButton onClick={() => setShowSelectionDialog(true)}>
              <SettingsIcon />
            </IconButton>
          </SettingsButtonBadge>
        </Box>
      </Tooltip>

      <ParametersSelectionDialog
        open={showSelectionDialog}
        onClose={() => {
          setShowSelectionDialog(false)
          setShowSelectionDialogHint(false)
        }}
        allParameterNames={parameterNames}
        selectedParameters={selectedParameters}
        setSelectedParameters={onSelectedParametersChanged}
      />
    </>
  )
}
