import { Tooltip } from '@dspace-internal/ui-kit'
import { TooltipProps } from '@mui/material'
import { Margin } from '@nivo/core'
import { CanvasLayer } from '@nivo/line'
import React from 'react'
import { useTimelineRange } from '../shared/TimelineRangeProvider'
import { getScaleFunctions } from '../shared/propertyDisplayUtils'
import { timelineSegmentHeight } from './TimelineSegments'

export interface TimelineMarkerItem {
  timestamp: number
  color: string
  iconPath: string
  tooltip: TooltipProps['title']
}

const timelineMarkerWidth = 36
export const timelineMarkerHeight = 32
const markerSvgOffsetX = timelineMarkerWidth / 2
const markerSvgOffsetY = timelineMarkerHeight + 1

const markerPath = new Path2D(
  'M 19 9 C 19 14.25 12 22 12 22 C 12 22 5 14.25 5 9 C 5 5.13 8.13 2 12 2 C 15.87 2 19 5.13 19 9 Z M 12 3.5 C 8.962 3.5 6.5 5.962 6.5 9 C 6.5 12.038 8.962 14.5 12 14.5 C 15.038 14.5 17.5 12.038 17.5 9 C 17.5 5.962 15.038 3.5 12 3.5 Z'
)

export interface MarkerPositionInfo {
  marker: TimelineMarkerItem
  x: number
  y: number
}

const getHoveredMarker = (
  markers: MarkerPositionInfo[],
  cursorX: number,
  cursorY: number
): MarkerPositionInfo | undefined => {
  if (markers.length > 0) {
    let closest: MarkerPositionInfo | undefined
    let closestDistance: number
    markers.forEach((marker) => {
      if (
        cursorX >= marker.x &&
        cursorX <= marker.x + timelineMarkerWidth &&
        cursorY >= marker.y &&
        cursorY <= marker.y + timelineMarkerHeight
      ) {
        const distance = Math.abs(marker.x + timelineMarkerWidth / 2 - cursorX)
        if (!closest || distance < closestDistance) {
          closest = marker
          closestDistance = distance
        }
      }
    })

    return closest
  } else {
    return undefined
  }
}

export interface UseMarkerLayerOptions {
  markers: TimelineMarkerItem[]
  margin: Pick<Margin, 'left' | 'top'>
  onMarkerHovered: (hoveredMarker: MarkerPositionInfo) => void
}

export const useMarkerLayer = ({
  markers,
  margin,
  onMarkerHovered,
}: UseMarkerLayerOptions) => {
  const markerPositions = React.useRef<MarkerPositionInfo[]>([])
  const [hoveredMarker, setHoveredMarker] = React.useState<
    MarkerPositionInfo | undefined
  >(undefined)

  const { currentRange } = useTimelineRange()

  const MarkerLayer: CanvasLayer = (props) => {
    const { xScale, yScale } = getScaleFunctions(props)
    const y = yScale(0) - timelineSegmentHeight - markerSvgOffsetY

    const canvas = props.ctx

    const positions: MarkerPositionInfo[] = []

    markers.forEach((marker) => {
      if (
        marker.timestamp < currentRange.minValue ||
        marker.timestamp > currentRange.maxValue
      ) {
        /* Markers aren't clipped so they won't get cut in half when they're on the very left or right of the timeline
           instead they're only drawn when they're in the visible range*/
        return
      }

      canvas.save()

      const x = xScale(marker.timestamp) - markerSvgOffsetX
      canvas.translate(x, y)
      positions.push({ marker, x, y })

      // Draw background circle so there won't be a transparent background inside the marker
      canvas.fillStyle = 'white'
      const backCircle = new Path2D()
      backCircle.ellipse(timelineMarkerWidth / 2, 13, 10, 10, 0, 0, Math.PI * 2)
      canvas.fill(backCircle)

      // Draw the marker shape
      if (marker === hoveredMarker?.marker) {
        canvas.globalAlpha = 0.6
      }

      canvas.fillStyle = marker.color

      canvas.scale(1.5, 1.5)
      canvas.fill(markerPath)

      // Draw the icon inside the marker
      canvas.translate(7.25, 4.25)
      canvas.scale(0.4, 0.4)
      canvas.fill(new Path2D(marker.iconPath))

      canvas.restore()
    })

    markerPositions.current = positions
  }

  const onMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const cursorX = event.nativeEvent.offsetX - margin.left
    const cursorY = event.nativeEvent.offsetY - margin.top

    const target = getHoveredMarker(markerPositions.current, cursorX, cursorY)

    if (target) {
      const hoveredMarker = {
        marker: target.marker,
        x: event.clientX - cursorX + target.x + timelineMarkerWidth / 2,
        y: event.clientY - cursorY + target.y + 10,
      }
      setHoveredMarker(hoveredMarker)
      onMarkerHovered(hoveredMarker)
    } else {
      setHoveredMarker(undefined)
    }
  }

  const onMouseLeave = () => {
    setHoveredMarker(undefined)
  }

  return {
    MarkerLayer,
    markerHandlers: { onMouseMove, onMouseLeave },
    hoveredMarker,
  }
}

export interface MarkerTooltipProps {
  targetMarker: MarkerPositionInfo | undefined
}

export const MarkerTooltip: React.FC<MarkerTooltipProps> = ({
  targetMarker,
}) => {
  return (
    <Tooltip
      message={targetMarker?.marker.tooltip || ''}
      open={targetMarker !== undefined}
      PopperProps={{
        anchorEl: {
          getBoundingClientRect: () =>
            new DOMRect(
              targetMarker?.x,
              targetMarker ? targetMarker.y - 4 : undefined,
              0,
              0
            ),
        },
      }}
      TransitionProps={{
        timeout: 50,
      }}
      placement="top"
      arrow
    >
      <span />
    </Tooltip>
  )
}
