import React from 'react'

import {
  createEvent,
  createEventHubProvider,
  EventHubEvent,
} from '../../../../EventHub'

export interface TimelineEvents {
  onTimelineClick: EventHubEvent<OnTimelineClickEventProps>
}

export interface OnTimelineClickEventProps {
  cursorPosition: number
}

const { EventHub, useEventHubEvents } = createEventHubProvider<TimelineEvents>()
export const useTimelineEvents = useEventHubEvents

export interface TimelineEventHubProps {}

const TimelineEventHub: React.FC<
  React.PropsWithChildren<TimelineEventHubProps>
> = ({ children }) => {
  const events: TimelineEvents = React.useMemo(
    () => ({
      onTimelineClick: createEvent<OnTimelineClickEventProps>(),
    }),
    []
  )

  return <EventHub events={events}>{children}</EventHub>
}

export default TimelineEventHub
