import { SidebarSubGroup, SidebarSubGroupItem } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { DrtIcon, PATHS } from '@simphera/shared/ui-simphera'
import React from 'react'
import { useParams } from 'react-router-dom'
import { wrapComponentInProviders } from './client/wrapper'

const SuitesPage = React.lazy(
  () => import('./components/SuitesPage/SuitesPage')
)
const NewSuitePage = React.lazy(
  () => import('./components/SuiteDetailsPage/NewSuitePage')
)
const SuiteDetailsPage = React.lazy(
  () => import('./components/SuiteDetailsPage/SuiteDetailsPage')
)
const NewTestCasePage = React.lazy(
  () => import('./components/TestCaseDetailsPage/NewTestCasePage')
)
const TestCaseDetailsPage = React.lazy(
  () => import('./components/TestCaseDetailsPage/TestCaseDetailsPage')
)
const RunsPage = React.lazy(() => import('./components/RunsPage/RunsPage'))
const RunDetailsPage = React.lazy(() => import('./components/RunDetailsPage'))
const TestCaseResultDetailsPage = React.lazy(
  () => import('./components/TestCaseResultDetailsPage')
)
const RecordingResultDetailsPage = React.lazy(
  () => import('./components/RecordingResultDetailsPage')
)

const SidebarEntries = () => {
  const { projectId } = useParams()

  return (
    <SidebarSubGroup name="Data Replay" icon={<DrtIcon />}>
      <SidebarSubGroupItem
        name="Suites"
        to={PATHS.DRT.SUITES_LIST(projectId!)}
        customActivateFn={({ location }) =>
          location.pathname.includes('datareplay') &&
          !location.pathname.includes('runs') &&
          !location.pathname.includes('results')
        }
      />
      <SidebarSubGroupItem
        name="Runs"
        to={PATHS.DRT.RUNS_LIST(projectId!)}
        customActivateFn={({ location }) =>
          location.pathname.includes('datareplay') &&
          (location.pathname.includes('runs') ||
            location.pathname.includes('results'))
        }
      />
    </SidebarSubGroup>
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.DATAREPLAY_APP_ENABLED,
  sidebarEntries: [<SidebarEntries />],
  projectItemEntry: {
    url: (projectId: string) => PATHS.DRT.SUITES_LIST(projectId),
    icon: <DrtIcon />,
    label: 'Data Replay',
  },
  routes: [
    {
      path: PATHS.DRT.SUITES_LIST(':projectId'),
      element: wrapComponentInProviders(SuitesPage),
    },
    {
      path: PATHS.DRT.SUITES_NEW(':projectId'),
      element: wrapComponentInProviders(NewSuitePage),
    },
    {
      path: PATHS.DRT.SUITES_DETAIL(':projectId', ':suiteId'),
      element: wrapComponentInProviders(SuiteDetailsPage),
    },
    {
      path: PATHS.DRT.TESTCASES_NEW(':projectId'),
      element: wrapComponentInProviders(NewTestCasePage),
    },
    {
      path: PATHS.DRT.TESTCASES_DETAIL(':projectId', ':testCaseId'),
      element: wrapComponentInProviders(TestCaseDetailsPage),
    },
    {
      path: PATHS.DRT.RUNS_DETAIL(':projectId', ':runId'),
      element: wrapComponentInProviders(RunDetailsPage),
    },
    {
      path: PATHS.DRT.RUNS_LIST(':projectId'),
      element: wrapComponentInProviders(RunsPage),
    },
    {
      path: PATHS.DRT.RUNS_TESTCASE(':projectId', ':runId', ':testCaseId'),
      element: wrapComponentInProviders(TestCaseResultDetailsPage),
    },
    {
      path: PATHS.DRT.RESULT_DETAIL(':projectId', ':resultId'),
      element: wrapComponentInProviders(RecordingResultDetailsPage),
    },
  ],
}
