import { fmt } from './messageUtils'

export const descriptions_res = {
  EXECUTION_TIME: () =>
    fmt('The execution time is the accumulated time of all simulations.'),
  RUN_DURATION: () =>
    fmt(
      'The run duration is the time span from the start of the first simulation to the end of the last simulation within the run or test case result.'
    ),
  RUN_JOBPRIORITY: () =>
    fmt(
      'Lower values represent higher priorities. Negative values are allowed as well. The priority value 0 is a special case, which is the default priority for validation jobs and cannot be explicitly assigned by an API client or user.'
    ),
  PARAMETER_SELECTION_DIALOG_HELP_TEXT: () =>
    fmt(`Drag and drop parameters to assign them to a plot axis.`),
}

export default descriptions_res
