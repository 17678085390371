import { createProviderHook } from '@simphera/shared/ui-simphera'
import React from 'react'
import { KpiVisualizationProperty } from '../../KpiVisualiziationTypes'
import { VideoTimeCursorProvider } from '../../VideoDisplay/VideoTimeCursorProvider'
import TimelineEventHub from './TimelineEventHub'
import { getPropertyTimestamps } from './getPropertyTimestamps'

export interface TimelineRange {
  minValue: number
  maxValue: number
}

export interface TimelineRangeContextData {
  timelineRange: TimelineRange
  addTimestampsToTimelineRange: (addedTimestamps: number[]) => void
  currentRange: TimelineRange
  setCurrentRange: React.Dispatch<React.SetStateAction<TimelineRange>>
  cursorPosition: number | undefined
  setCursorPosition: (cursorPosition: number | undefined) => void
}

const TimelineRangeContext = React.createContext<
  TimelineRangeContextData | undefined
>(undefined)

const calculateTimelineRange = (timestamps: number[]) => {
  const range = {
    minValue: Number.MAX_VALUE,
    maxValue: Number.MIN_VALUE,
  }

  timestamps.forEach((timestamp) => {
    if (timestamp < range.minValue) {
      range.minValue = timestamp
    }
    if (timestamp > range.maxValue) {
      range.maxValue = timestamp
    }
  })

  return range
}

interface TimelineRangeProviderProps {
  properties: KpiVisualizationProperty[]
}

const TimelineRangeProvider: React.FC<
  React.PropsWithChildren<TimelineRangeProviderProps>
> = ({ properties, children }) => {
  const [timelineRange, setTimelineRange] = React.useState(() => {
    const timestamps = getPropertyTimestamps(properties)

    if (timestamps.length === 0) {
      return {
        minValue: 0,
        maxValue: 1,
      }
    } else if (timestamps.length === 1) {
      const start = timestamps[0] - 1
      if (start < 0) {
        return {
          minValue: 0,
          maxValue: 1,
        }
      } else {
        return {
          minValue: start,
          maxValue: timestamps[0] + 1,
        }
      }
    }

    return calculateTimelineRange(timestamps)
  })

  const addTimestampsToTimelineRange = React.useCallback(
    (timestamps: number[]) => {
      setTimelineRange((currentTimelineRange) => {
        const newTimelineRange = calculateTimelineRange([
          currentTimelineRange.minValue,
          currentTimelineRange.maxValue,
          ...timestamps,
        ])

        // Update currently displayed range to new max range if it is not currently zoomed in
        setCurrentRange((currentRange) =>
          currentRange.minValue === currentTimelineRange.minValue &&
          currentRange.maxValue === currentTimelineRange.maxValue
            ? newTimelineRange
            : currentRange
        )

        return newTimelineRange
      })
    },
    []
  )

  const [currentRange, setCurrentRange] =
    React.useState<TimelineRange>(timelineRange)
  const [cursorPosition, setCursorPosition] = React.useState<
    number | undefined
  >(undefined)

  return (
    <TimelineRangeContext.Provider
      value={{
        timelineRange,
        addTimestampsToTimelineRange,
        currentRange,
        setCurrentRange,
        cursorPosition,
        setCursorPosition,
      }}
    >
      <VideoTimeCursorProvider>
        <TimelineEventHub>{children}</TimelineEventHub>
      </VideoTimeCursorProvider>
    </TimelineRangeContext.Provider>
  )
}

export const useTimelineRange = createProviderHook(
  TimelineRangeContext,
  'TimelineRangeProvider',
  'useTimelineRange'
)

export default TimelineRangeProvider
