import { SidebarSubGroup, SidebarSubGroupItem } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { PATHS, StepbtIcon } from '@simphera/shared/ui-simphera'
import React from 'react'
import { useParams } from 'react-router-dom'
import { wrapComponentInProviders } from './client/wrapper'

const NewSuitePage = React.lazy(
  () => import('./components/SuiteDetailsPage/NewSuitePage')
)
const SuiteListPage = React.lazy(
  () => import('./components/SuiteListPage/SuiteListPage')
)
const SuiteDetailsPage = React.lazy(
  () => import('./components/SuiteDetailsPage/SuiteDetailsPage')
)
const NewLogicalTestCasePage = React.lazy(
  () => import('./components/LogicalTestCasePage/NewLogicalTestCasePage')
)
const TestCaseDetailsPage = React.lazy(
  () => import('./components/TestCaseDetailsPage/TestCaseDetailsPage')
)
const LogicalTestCasePage = React.lazy(
  () => import('./components/LogicalTestCasePage/LogicalTestCasePage')
)
const RunListPage = React.lazy(
  () => import('./components/RunListPage/RunListPage')
)
const RunDetailsPage = React.lazy(
  () => import('./components/RunDetailsPage/RunDetailsPage')
)
const LibraryListPage = React.lazy(
  () => import('./components/LibraryListPage/LibraryListPage')
)
const TestCaseResultsPage = React.lazy(
  () => import('./components/TestCaseResultsPage/TestCaseResultsPage')
)

const SidebarEntries = () => {
  const { projectId } = useParams()

  return (
    <SidebarSubGroup name="Step-based" icon={<StepbtIcon />}>
      <SidebarSubGroupItem
        name="Suites"
        to={PATHS.STPEBT.SUITES_LIST(projectId!)}
      />
      <SidebarSubGroupItem
        name="Runs"
        to={PATHS.STPEBT.RUNS_LIST(projectId!)}
      />
      <SidebarSubGroupItem
        name="Libraries"
        to={PATHS.STPEBT.LIRBRARIES_LIST(projectId!)}
      />
    </SidebarSubGroup>
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.STEPBT_APP_ENABLED,
  sidebarEntries: [<SidebarEntries />],
  projectItemEntry: {
    url: (projectId: string) => PATHS.STPEBT.SUITES_LIST(projectId!),
    icon: <StepbtIcon />,
    label: 'Step-based',
  },
  routes: [
    {
      path: PATHS.STPEBT.SUITES_LIST(':projectId'),
      element: wrapComponentInProviders(SuiteListPage),
    },
    {
      path: PATHS.STPEBT.SUITES_NEW(':projectId'),
      element: wrapComponentInProviders(NewSuitePage),
    },
    {
      path: PATHS.STPEBT.SUITES_DETAIL(':projectId', ':suiteId'),
      element: wrapComponentInProviders(SuiteDetailsPage),
    },
    {
      path: PATHS.STPEBT.SUITES_TESTCASES_NEW(':projectId', ':suiteId'),
      element: wrapComponentInProviders(NewLogicalTestCasePage),
    },
    {
      path: PATHS.STPEBT.SUITES_TESTCASES_DETAIL(
        ':projectId',
        ':suiteId',
        ':logicalTestCaseId'
      ),
      element: wrapComponentInProviders(LogicalTestCasePage),
    },
    {
      path: PATHS.STPEBT.LIRBRARIES_TESTCASES_DETAIL(
        ':projectId',
        ':libraryId',
        ':testCaseId'
      ),
      element: wrapComponentInProviders(TestCaseDetailsPage),
    },
    {
      path: PATHS.STPEBT.RUNS_TESTCASE_RESULT(
        ':projectId',
        ':runId',
        ':testCaseResultId'
      ),
      element: wrapComponentInProviders(TestCaseResultsPage),
    },
    {
      path: PATHS.STPEBT.RUNS_LIST(':projectId'),
      element: wrapComponentInProviders(RunListPage),
    },
    {
      path: PATHS.STPEBT.RUNS_DETAIL(':projectId', ':runId'),
      element: wrapComponentInProviders(RunDetailsPage),
    },
    {
      path: PATHS.STPEBT.LIRBRARIES_LIST(':projectId'),
      element: wrapComponentInProviders(LibraryListPage),
    },
  ],
}
