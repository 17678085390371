import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  ExperimentIcon,
  PATHS,
  SidebarItemInterpolate,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import { captions_res } from './shared/resources'

const ExperimentsPage = React.lazy(() => import('./components/ExperimentsPage'))
const ExperimentDetailPage = React.lazy(
  () => import('./components/ExperimentDetailPage')
)
const ExperimentEditPage = React.lazy(
  () => import('./components/ExperimentEditPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.EXPERIMENT_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name={captions_res.EXPERIMENTS()}
      icon={<ExperimentIcon />}
      to={PATHS.EXPERIMENTS.LIST(':projectId')}
    />,
  ],
  projectItemEntry: {
    url: (projectId: string) => PATHS.EXPERIMENTS.LIST(projectId),
    icon: <ExperimentIcon />,
    label: captions_res.EXPERIMENTS(),
  },
  routes: [
    {
      path: PATHS.EXPERIMENTS.LIST(':projectId'),
      element: wrapComponentInProviders(ExperimentsPage),
    },
    {
      path: PATHS.EXPERIMENTS.CREATE(':projectId'),
      element: wrapComponentInProviders(ExperimentEditPage),
    },
    {
      path: PATHS.EXPERIMENTS.DETAIL(':projectId', ':experimentId'),
      element: wrapComponentInProviders(ExperimentDetailPage),
    },
    {
      path: PATHS.EXPERIMENTS.EDIT(':projectId', ':experimentId'),
      element: wrapComponentInProviders(ExperimentEditPage),
    },
  ],
}
