import { TextField } from '@dspace-internal/ui-kit'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../../utils/messages.res'
import { FILE_PATH_CHAR_LIMIT } from './constants'
import { IReadOnly } from './types'

export const RepositoryUrlInput: React.FC<IReadOnly> = ({
  isReadOnly = false,
}) => {
  const { control, formState } =
    useFormContext<Required<{ repositoryUrl: string }>>()
  const error = formState.errors.repositoryUrl

  return (
    <Controller
      control={control}
      name="repositoryUrl"
      defaultValue=""
      rules={{
        validate: {
          isValidLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(FILE_PATH_CHAR_LIMIT),
            FILE_PATH_CHAR_LIMIT
          ),
          isValidUrl: createValidator.isValidUrl(messages.INVALID_URL()),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          name={field.name}
          error={Boolean(error)}
          label="URL"
          size="small"
          type="url"
          fullWidth
          helperText={error?.message}
          disabled={isReadOnly}
          autoComplete="off"
        />
      )}
    />
  )
}
