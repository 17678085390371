import { PATHS } from '@simphera/shared/ui-simphera'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { ApiKeysCreatePage } from '../ApiKeysPage/ApiKeysCreatePage'
import { ApiKeysPage } from '../ApiKeysPage/ApiKeysPage'
import { Authentication } from '../Authentication'
import { NotFoundPage } from '../NotFoundPage'
import { AppFrame } from './AppFrame'
import { ErrorPage } from './ErrorPage'
import { getRoutes } from './navigationConfigs'

export const routes: Array<RouteObject> = [
  {
    path: PATHS.ROOT(),
    element: (
      <AppFrame>
        <Outlet />
      </AppFrame>
    ),
    errorElement: (
      <AppFrame>
        <ErrorPage />
      </AppFrame>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={PATHS.PROJECTS.LIST()} replace />,
      },
      {
        path: PATHS.AUTHENTICATION(),
        element: <Authentication />,
      },
      {
        path: PATHS.APIKEYS.LIST(),
        element: <ApiKeysPage />,
      },
      {
        path: PATHS.APIKEYS.NEW(),
        element: <ApiKeysCreatePage />,
      },
      ...getRoutes(),
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]
