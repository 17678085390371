import { ErrorResponse } from '@apollo/client/link/error'
import HttpStatus from 'http-status-codes'
import { signOutRedirectWithState } from '../userManager'

export const handleError = (
  error: ErrorResponse,
  signOutWhenUnauthorized = false
) => {
  const { operation, graphQLErrors, networkError } = error

  if (
    signOutWhenUnauthorized &&
    operation.getContext()?.response?.status === HttpStatus.UNAUTHORIZED
  ) {
    signOutRedirectWithState()
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => console.error({ graphQLError }))
  }

  if (networkError) {
    console.error({ networkError })
  }
}
