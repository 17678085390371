import React from 'react'

type CallbackFunction = () => void

/** use this for polling. only runs when document is active (i.e. tab is active, browser not minimized, ...) */
export function usePollingInterval(
  callback: CallbackFunction,
  delayMs: number,
  runInitially: boolean = false
) {
  const savedCallback = React.useRef<CallbackFunction>(callback)
  const timeoutRef = React.useRef<NodeJS.Timeout>()
  const hasSkippedPolls = React.useRef(false)

  React.useEffect(() => {
    if (runInitially) {
      tick()
    }

    document.addEventListener('visibilitychange', onVisibilityChanged)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged)
      clearPotentialTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const tick = React.useCallback(() => {
    if (document.visibilityState === 'visible') {
      // execute callback, schedule next tick
      savedCallback.current()
      clearPotentialTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(tick, delayMs)
    } else {
      // set hasSkippedPolls, clear timeout
      hasSkippedPolls.current = true
      clearPotentialTimeout(timeoutRef.current)
    }
  }, [delayMs])

  const onVisibilityChanged = React.useCallback(() => {
    // immediately run callback to prevent interval from never running if the user switches away shortly before it runs
    if (document.visibilityState === 'visible' && hasSkippedPolls.current) {
      savedCallback.current()
      hasSkippedPolls.current = false
      clearPotentialTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(tick, delayMs)
    }
  }, [delayMs, tick])
}

function clearPotentialTimeout(timeout?: NodeJS.Timeout) {
  if (timeout) {
    clearTimeout(timeout)
  }
}
