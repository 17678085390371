import { Tooltip } from '@dspace-internal/ui-kit'
import { formatDistance, isValid } from 'date-fns'
import React, { useState } from 'react'
import { useInterval } from '../../hooks'

const REFRESH_INTERVAL_EVERY_MINUTE_MS = 1000 * 60

export interface RelativeTimeProps {
  date: Date
  preventTooltip?: boolean
  refreshIntervalMs?: number
  underline?: boolean
}

const UNDERLINE_STYLES: React.CSSProperties = {
  textDecoration: 'underline',
  textDecorationStyle: 'dotted',
  textUnderlineOffset: '2px',
  textDecorationThickness: '1px',
}

export const RelativeTime: React.FC<RelativeTimeProps> = (props) => {
  const isValidDate = isValid(props.date) && props.date.getTime()

  return isValidDate ? <RelativeTimeRenderer {...props} /> : null
}

const RelativeTimeRenderer: React.FC<RelativeTimeProps> = ({
  date,
  preventTooltip = false,
  refreshIntervalMs = REFRESH_INTERVAL_EVERY_MINUTE_MS,
  underline = false,
}) => {
  const [now, setNow] = useState(new Date())

  useInterval(() => {
    setNow(new Date())
  }, refreshIntervalMs)

  const text = formatDistance(date, now, { addSuffix: true })
  const textElement = (
    <span style={underline ? UNDERLINE_STYLES : {}}>{text}</span>
  )

  return preventTooltip ? (
    textElement
  ) : (
    <Tooltip message={date.toLocaleString()}>{textElement}</Tooltip>
  )
}
