import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  PATHS,
  SensorIcon,
  SidebarItemInterpolate,
  VehicleIcon,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import { SHOW_AURELION_FEATURES } from './config'

const VehicleListPage = React.lazy(
  () => import('./components/VehicleListPage/VehicleListPage')
)
const SensorsPage = React.lazy(
  () => import('./components/SensorsPage/SensorsPage')
)
const VehicleDetailsPage = React.lazy(
  () => import('./components/VehicleDetailsPage/VehicleDetailsPage')
)
const SensorDetailsPage = React.lazy(
  () => import('./components/SensorDetailsPage/SensorDetailsPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.VEHICLES_APP_ENABLED,
  sidebarEntries: [
    ...(SHOW_AURELION_FEATURES()
      ? [
          <SidebarItemInterpolate
            name="Sensors"
            icon={<SensorIcon />}
            to={PATHS.SENSORS.LIST(':projectId')}
          />,
        ]
      : []),
    <SidebarItemInterpolate
      name="Vehicles"
      icon={<VehicleIcon />}
      to={PATHS.VEHICLES.LIST(':projectId')}
    />,
  ],
  projectItemEntry: {
    url: (projectId: string) => `/projects/${projectId}/vehicles`,
    icon: <VehicleIcon />,
    label: 'Vehicles',
  },
  routes: [
    {
      path: PATHS.VEHICLES.LIST(':projectId'),
      element: wrapComponentInProviders(VehicleListPage),
    },
    {
      path: PATHS.VEHICLES.DETAIL(':projectId', ':vehicleId'),
      element: wrapComponentInProviders(VehicleDetailsPage),
    },
    ...(SHOW_AURELION_FEATURES()
      ? [
          {
            path: PATHS.SENSORS.LIST(':projectId'),
            element: wrapComponentInProviders(SensorsPage),
          },
          {
            path: PATHS.SENSORS.DETAIL(':projectId', ':sensorId'),
            element: wrapComponentInProviders(SensorDetailsPage),
          },
          {
            path: PATHS.SENSORS.CREATE(':projectId', ':sensorType'),
            element: wrapComponentInProviders(SensorDetailsPage),
          },
        ]
      : []),
  ].filter(Boolean),
}
