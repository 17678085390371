import {
  HistogramProperty,
  KpiVisualizationProperty,
  LinePlotProperty,
  ScatterPlotProperty,
  TimelineBase,
} from '../../KpiVisualiziationTypes'

const noTimestamps = (): number[] => {
  return []
}

const getTimelineTimestamps = (property: TimelineBase<unknown, unknown>) => {
  return property.data.length > 0
    ? property.data.flatMap((entry) => {
        if (entry.end === undefined) {
          return [entry.start]
        } else {
          return [entry.start, entry.end]
        }
      })
    : []
}

const getHistogramTimestamps = (property: HistogramProperty) => {
  return property.series.flatMap((series) =>
    series.bins.flatMap((bin) => [bin.min, bin.max])
  )
}

const getPointPlotTimestamps = (
  property: LinePlotProperty | ScatterPlotProperty
) => {
  return property.series.flatMap((series) =>
    series.points.map((point) => point.x)
  )
}

const getTimestampsMapping: Record<
  KpiVisualizationProperty['type'],
  (property: any) => number[]
> = {
  Group: noTimestamps,
  Subheader: noTimestamps,
  String: noTimestamps,
  Tile: noTimestamps,
  VerdictTimeline: getTimelineTimestamps,
  StringTimeline: getTimelineTimestamps,
  Histogram: getHistogramTimestamps,
  LinePlot: getPointPlotTimestamps,
  ScatterPlot: getPointPlotTimestamps,
  BarChart: noTimestamps,
  Video: noTimestamps,
  Reference: noTimestamps,
}

export const getPropertyTimestamps = (
  properties: KpiVisualizationProperty[]
) => {
  if (properties) {
    return properties.flatMap((property) =>
      getTimestampsMapping[property.type](property)
    )
  } else {
    return []
  }
}
