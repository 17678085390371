import { SidebarSubGroup, SidebarSubGroupItem } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { PATHS, ScbtIcon } from '@simphera/shared/ui-simphera'
import { lazy } from 'react'
import { useParams } from 'react-router-dom'
import { wrapComponentInProviders } from './utils/utils'

const SuiteListPage = lazy(() => import('./components/Suite/SuiteListPage'))
const TestCaseResultDetailPage = lazy(
  () => import('./components/Run/TestCaseResultDetailPage')
)
const SuiteDetailPage = lazy(() => import('./components/Suite/SuiteDetailPage'))
const NewSuitePage = lazy(() => import('./components/Suite/NewSuitePage'))
const RunListPage = lazy(() => import('./components/Run/RunListPage'))
const RunDetailPage = lazy(() => import('./components/Run/RunDetailPage'))
const ResultDetailPage = lazy(() => import('./components/Run/ResultDetailPage'))
const LogicalTestCaseDetailPage = lazy(
  () => import('./components/LogicalTestCase/LogicalTestCaseDetailPage')
)
const NewLogicalTestCasePage = lazy(
  () => import('./components/LogicalTestCase/NewLogicalTestCasePage')
)

const SidebarEntries = () => {
  const { projectId } = useParams()

  return (
    <SidebarSubGroup name="Scenario-based" icon={<ScbtIcon />}>
      <SidebarSubGroupItem
        name="Suites"
        to={PATHS.SCBT.SUITE_LIST(projectId!)}
        customActivateFn={({ location }) =>
          location.pathname.includes('scenario-based') &&
          !location.pathname.includes('runs') &&
          !location.pathname.includes('results')
        }
      />
      <SidebarSubGroupItem
        name="Runs"
        to={PATHS.SCBT.RUNS_LIST(projectId!)}
        customActivateFn={({ location }) =>
          location.pathname.includes('scenario-based') &&
          (location.pathname.includes('runs') ||
            location.pathname.includes('results'))
        }
      />
    </SidebarSubGroup>
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.SCBT_APP_ENABLED,
  sidebarEntries: [<SidebarEntries />],
  projectItemEntry: {
    url: (projectId: string): string => PATHS.SCBT.SUITE_LIST(projectId),
    icon: <ScbtIcon />,
    label: 'Scenario-based',
  },
  routes: [
    {
      path: PATHS.SCBT.SUITE_LIST(':projectId'),
      element: wrapComponentInProviders(SuiteListPage),
    },
    // important: 'new suite' route must be defined before 'suite detail' route
    {
      path: PATHS.SCBT.SUITE_NEW(':projectId'),
      element: wrapComponentInProviders(NewSuitePage),
    },
    {
      path: PATHS.SCBT.SUITE_DETAIL(':projectId', ':suiteId'),
      element: wrapComponentInProviders(SuiteDetailPage),
    },
    {
      path: PATHS.SCBT.TESTCASE_NEW(':projectId'),
      element: wrapComponentInProviders(NewLogicalTestCasePage),
    },
    {
      path: PATHS.SCBT.TESTCASE_DETAIL(':projectId', ':testCaseId'),
      element: wrapComponentInProviders(LogicalTestCaseDetailPage),
    },
    {
      path: PATHS.SCBT.RUNS_LIST(':projectId'),
      element: wrapComponentInProviders(RunListPage),
    },
    {
      path: PATHS.SCBT.RUNS_DETAIL(':projectId', ':runId'),
      element: wrapComponentInProviders(RunDetailPage),
    },
    {
      path: PATHS.SCBT.RUNS_TESTCASE(':projectId', ':runId', ':testCaseId'),
      element: wrapComponentInProviders(TestCaseResultDetailPage),
    },
    {
      path: PATHS.SCBT.RESULT(':projectId', ':resultId'),
      element: wrapComponentInProviders(ResultDetailPage),
    },
  ],
}
