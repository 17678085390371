import { TextField } from '@dspace-internal/ui-kit'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../../utils/messages.res'
import { NAME_CHAR_LIMIT } from './constants'
import { IReadOnly } from './types'

const DEFAULT_BRANCH_NAME_LOCAL_STORAGE_KEY = 'branchToImport'

export const getDefaultBranchName = () =>
  localStorage.getItem(DEFAULT_BRANCH_NAME_LOCAL_STORAGE_KEY) || ''

export const setDefaultBranchName = (branch: string = '') =>
  localStorage.setItem(DEFAULT_BRANCH_NAME_LOCAL_STORAGE_KEY, branch)

export const BranchInput: React.FC<IReadOnly> = ({ isReadOnly = false }) => {
  const { control, formState, getValues } =
    useFormContext<Required<{ branch: string }>>()
  const error = formState.errors.branch

  return (
    <Controller
      control={control}
      name="branch"
      defaultValue=""
      rules={{
        required: messages.INPUT_CANNOT_BE_EMPTY('branch or tag'),
        validate: {
          validLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(NAME_CHAR_LIMIT),
            NAME_CHAR_LIMIT
          ),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          name={field.name}
          error={Boolean(error)}
          size="small"
          label="Branch or Tag"
          fullWidth
          helperText={error?.message}
          disabled={isReadOnly}
          required
          autoComplete="off"
          onBlur={() => setDefaultBranchName(getValues('branch'))}
        />
      )}
    />
  )
}
