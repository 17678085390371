import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

const StyledHelpText = styled.div`
  margin-bottom: 16px;
  color: ${colors.black};
`

export interface HelpTextProps {
  text: string
}

export const HelpText: React.FC<HelpTextProps> = ({ text }) => {
  return <StyledHelpText>{text}</StyledHelpText>
}
