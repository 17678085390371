export const MAPPED_GLOBAL_VARIABLES = [
  {
    globalVar: '__APP_CONFIG__',
    localVar: 'appConfig',
  },
  {
    globalVar: '__FEATURE_FLAGS__',
    localVar: 'featureFlags',
  },
  {
    globalVar: '__PROJECT_APP_CONFIG__',
    localVar: 'project',
  },
  {
    globalVar: '__SCBT_APP_CONFIG__',
    localVar: 'scbt',
  },
  {
    globalVar: '__SCENARIO_APP_CONFIG__',
    localVar: 'scenarios',
  },
  {
    globalVar: '__VEHICLE_APP_CONFIG__',
    localVar: 'vehicle',
  },
  {
    globalVar: '__STEPBT_APP_CONFIG__',
    localVar: 'stepbt',
  },
  {
    globalVar: '__DATAREPLAY_APP_CONFIG__',
    localVar: 'datareplay',
  },
  {
    globalVar: '__EXPERIMENT_APP_CONFIG__',
    localVar: 'experiment',
  },
  {
    globalVar: '__SYSTEMUNDERTEST_APP_CONFIG__',
    localVar: 'systemundertest',
  },
  {
    globalVar: '__KPI_APP_CONFIG__',
    localVar: 'kpi',
  },
  {
    globalVar: '__TESTENVIRONMENT_APP_CONFIG__',
    localVar: 'testenvironment',
  },
  {
    globalVar: '__CORE_SERVICE_CONFIG__',
    localVar: 'coreService',
  },
]
