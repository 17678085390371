import React from 'react'
import { createProviderHook } from '../utils'
import {
  KpiVisualizationProperty,
  VideoProperty,
  getAllProperties,
} from '../PropertyDisplays/KpiVisualiziationTypes'

export interface VideoPropertyLoaderContextData {
  loading: boolean
  getArtifactUrl: (nodeId: string, artifactName: string) => string
}

const VideoPropertyLoaderContext = React.createContext<
  VideoPropertyLoaderContextData | undefined
>(undefined)

type ArtifactNamesByNodeId = { nodeId: string; artifactNames: string[] }[]
type ArtifactUrlsByNodeId = {
  nodeId: string
  artifactUrlsByName: { name: string; downloadUrl: string }[]
}[]

export type GetArtifactDownloadUrlsSignature = (
  artifactNamesByNodeId: ArtifactNamesByNodeId
) => Promise<{
  artifactUrlsNodeId: ArtifactUrlsByNodeId | undefined
}>

export interface VideoPropertyLoaderProps {
  propertiesByNodeId: Record<string, KpiVisualizationProperty[]>
  loadingProperties: boolean
  getArtifactDownloadUrls: GetArtifactDownloadUrlsSignature
}

const VideoPropertyLoader: React.FC<
  React.PropsWithChildren<VideoPropertyLoaderProps>
> = ({
  propertiesByNodeId,
  loadingProperties,
  getArtifactDownloadUrls,
  children,
}) => {
  const [artifactsUrlsyNodeId, setArtifactsUrlsyNodeId] = React.useState<
    ArtifactUrlsByNodeId | undefined
  >(undefined)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (!loadingProperties) {
      const artifactNamesByNodeId: ArtifactNamesByNodeId = Object.keys(
        propertiesByNodeId
      )
        .map((nodeId) => {
          const videoProperties = getAllProperties(
            propertiesByNodeId[nodeId]
          ).filter(
            (property): property is VideoProperty => property.type === 'Video'
          )

          if (videoProperties.length > 0) {
            return {
              nodeId,
              artifactNames: videoProperties.map(
                (property) => property.videoArtifactName
              ),
            }
          } else {
            return undefined
          }
        })
        .filter(<T,>(item: T | undefined): item is T => item !== undefined)

      getArtifactDownloadUrls(artifactNamesByNodeId).then((result) => {
        setArtifactsUrlsyNodeId(result.artifactUrlsNodeId)
        setLoading(false)
      })
    }
  }, [getArtifactDownloadUrls, loadingProperties, propertiesByNodeId])

  const getArtifactUrl = React.useCallback(
    (nodeId: string, artifactName: string): string => {
      if (!artifactsUrlsyNodeId) {
        return ''
      }

      const artifact = artifactsUrlsyNodeId
        .find((artifactsByNodeId) => artifactsByNodeId.nodeId === nodeId)
        ?.artifactUrlsByName.find((artifact) => artifact.name === artifactName)

      return artifact?.downloadUrl ?? ''
    },
    [artifactsUrlsyNodeId]
  )

  return (
    <VideoPropertyLoaderContext.Provider
      value={{ loading: loading || loadingProperties, getArtifactUrl }}
    >
      {children}
    </VideoPropertyLoaderContext.Provider>
  )
}

export const useVideoPropertyLoader = createProviderHook(
  VideoPropertyLoaderContext,
  'VideoPropertyLoader',
  'useVideoPropertyLoader'
)

export default VideoPropertyLoader
