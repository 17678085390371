import { useDroppable } from '@dnd-kit/core'
import { captions_res } from '../../../../resources/captions'
import {
  DroppableContainer,
  DroppableContainerHeading,
} from './DroppableContainer'
import { ParameterDraggable } from './ParameterDraggable'
import { ParameterDraggablePlaceholder } from './ParameterDraggable.styles'

export interface SelectedParameterDroppableProps {
  iconPath: string
  label: string
  droppableId: string
  selectedParameter: string | undefined
}

export const SelectedParameterDroppable: React.FC<
  SelectedParameterDroppableProps
> = ({ iconPath, label, droppableId, selectedParameter }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: droppableId,
  })

  return (
    <DroppableContainer ref={setNodeRef} isHovered={isOver}>
      <DroppableContainerHeading iconPath={iconPath} label={label} />
      {selectedParameter ? (
        <ParameterDraggable
          key={selectedParameter}
          parameterName={selectedParameter}
        />
      ) : (
        <ParameterDraggablePlaceholder>
          {captions_res.PARAMETER_SELECTION_DROPZONE()}
        </ParameterDraggablePlaceholder>
      )}
    </DroppableContainer>
  )
}
