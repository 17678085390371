/* eslint-disable no-template-curly-in-string */
import { fmt } from './messageUtils'

export const messages_res = {
  GET_RESULT_FILE_DOWNLOAD_URL_ERROR: (errorMessage: string) =>
    fmt(`Could not download the result file. {errorMessage}`, { errorMessage }),
  COPY_RESULT_FILE_DOWNLOAD_URL_ERROR: (errorMessage: string) =>
    fmt(`Could not copy the result file download link. {errorMessage}`, {
      errorMessage,
    }),
  COPIED_RESULT_FILE_DOWNLOAD_URL: () =>
    fmt(
      `Copied result file download link to the clipboard. The link is valid for 24 hours.`
    ),
  PROCESSINGGRAPH_NO_VISUALIZATION_DATA_AVAILABLE: () =>
    fmt(`No visualization data available for this node.`),
  KPIVIS_NO_PROCESSING_OBJECT_SELECT: () =>
    fmt(`Select a processing object to view its results.`),
  KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE: (
    nodeId: string,
    propertyName: string,
    reason: string
  ) =>
    fmt(
      `The referenced property "{propertyName}" from the processing object "{nodeId}" could not be resolved. {reason}`,
      { nodeId, propertyName, reason }
    ),
  KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE_NODE_NOT_FOUND: () =>
    fmt(`No properties for the processing object are available.`),
  KPIVIS_REFERENCED_PROPERTY_UNRESOLVABLE_PROPERTY_NOT_FOUND: () =>
    fmt(`The processing object contains no such property.`),
  NO_PLOT_TOO_MANY_ITEMS: () =>
    fmt(
      `The 3D plot can be displayed only for runs with a maximum of 10,000 results.`
    ),
  NO_PLOT_NO_JOBS: () =>
    fmt(`Cannot display the 3D plot for runs without jobs.`),
  NO_PARAMETERS_TO_ADD: () => fmt('No more parameters to add.'),
  VALUE_REQUIRED: () => fmt('Value must be set.'),
  LOWERTHANSTOP: () => fmt('Must be lower than the stop value.'),
  GREATERTHANSTART: () => fmt('Must be greater than the start value.'),
  GREATERTHANZERO: () => fmt('Must be greater than 0.'),
  PROCESSINGGRAPH_SHOW_RESULTS: () => fmt(`Show evaluation results`),
  PROCESSINGGRAPH_NODE_IMPLICITLY_SELECTED: () =>
    fmt(`This node will be processed because a selected node depends on it.`),
  PROCESSINGGRAPH_CACHING_ENABLED: () => fmt(`Caching allowed.`),
  PROCESSINGGRAPH_CACHING_DISABLED: () => fmt(`Caching disabled.`),
  PROCESSINGGRAPH_CACHING_IMPLICITLY_DISABLED: () =>
    fmt(
      `This node cannot reuse cached data because it depends on a node that has caching disabled.`
    ),
  PROCESSINGGRAPH_CACHING_NOT_CONFIGURABLE: () =>
    fmt(`Caching can only be configured for processed nodes.`),
  NO_SEARCH_RESULTS: () => fmt('No search results'),
}

export const fieldInfoMessages_res = {
  NONE_SELECTED: () => fmt(`None`),
  UNAVAILABLE_WITH_BRACKETS: () => fmt(`'<unavailable'>`),
}

export default messages_res
