import { useCallback, useEffect, useRef, useState } from 'react'
// This type is generated by openapi-typescript and is identitcal accross all domains, we just needed to pick one here, so we took the one from KPI
import { ApiError, RestApiError } from '../generated/kpi'

export interface UseOpenApiQueryOptions<TQueryParams> {
  params: TQueryParams
  skip?: boolean
  onError?: (error: RestApiError) => void
  /** Optional settings to refetch data every provided milliseconds */
  pollingInterval?: number
}

interface UseOpenApiQueryResult<TData> {
  data: TData | undefined
  /** Is true whenever the query is fetching data. Use if you want to display the loading state whenever the query is refetching. */
  isLoading: boolean
  /** Only is true while data is being queried for the first time. Use if you don't want to display the loading state while refetching and display old data instead. */
  isLoadingInitialData: boolean
  error: RestApiError | undefined
  refetch: () => void
}

type ApiFunction<TData, TQueryParams> = (
  queryParams: TQueryParams
) => Promise<TData>

export const useOpenApiQuery = <TData, TQueryParams extends object>(
  apiFunction: ApiFunction<TData, TQueryParams>,
  options: UseOpenApiQueryOptions<TQueryParams>
): UseOpenApiQueryResult<TData> => {
  const { params, skip = false, pollingInterval } = options

  const [data, setData] = useState<TData | undefined>()
  const [isLoadingQuery, setIsLoadingQuery] = useState(!skip)
  const [error, setError] = useState<RestApiError | undefined>(undefined)

  const firstFetch = useRef(true)

  const refetch = useCallback(() => {
    setIsLoadingQuery(true)

    apiFunction(params)
      .then((data) => {
        setData(data)
        setError(undefined)
      })
      .catch((error: ApiError) => {
        // TODO: body is undefined when the URL can't be reached (404) or authorization fails (401), this needs to be handled and elevated into a page-wide error
        const restApiError = error.body as RestApiError

        console.error(restApiError)
        options.onError && options.onError(restApiError)
        setData(undefined)
        setError(restApiError)
      })
      .finally(() => {
        setIsLoadingQuery(false)
        firstFetch.current = false
      })

    // Dependency array must only contain query params
  }, Object.values(params))

  // TODO: We should be checking for deep equality ourselves, rather than using the shallow equality check of the useEffect dependency array
  // This will currently trigger unintended refetches on every single render if params contains objects or arrays that aren't persisted between renders.
  // (if this is a problem for you, please adapt this implementation rather than persisting your params)
  useEffect(() => {
    if (skip) {
      return
    }

    refetch()

    // refetch function instance changes when query params change, thereby this query refetches, when query params change
  }, [refetch, skip])

  // refetchInterval functionality
  useEffect(() => {
    // refetchInterval must be valid
    if (pollingInterval && pollingInterval > 0) {
      // set a timer of 'refetchInterval' milliseconds long
      const intervalId = setInterval(() => {
        console.count('interval called')
        // time is up and executes refetch, then uses 'setFetchResetter' as a signal to resetup the timer
        refetch()
      }, pollingInterval)
      return () => clearInterval(intervalId)
    }
    return undefined
  }, [isLoadingQuery, refetch])

  // Loading also needs to be true in the first rerender after skip flips to true
  const isLoading =
    isLoadingQuery || (!skip && data === undefined && error === undefined)

  return {
    data,
    isLoading,
    isLoadingInitialData: isLoading && firstFetch.current,
    error,
    refetch,
  }
}
