import { createProviderHook } from '@simphera/shared/ui-simphera'
import React from 'react'

export interface ProcessingObjectSelectionContextData {
  currentTab: string | undefined
  setCurrentTab: (currentTab: string | undefined) => void
  openTab: (currentTab: string | undefined) => void
  scrollTargetRef: React.RefObject<HTMLDivElement>
  resultLevel: 'testCaseResult' | 'result'
}

const ProcessingObjectSelectionContext = React.createContext<
  ProcessingObjectSelectionContextData | undefined
>(undefined)

export interface ProcessingObjectSelectionProviderProps {
  resultLevel: 'testCaseResult' | 'result'
}

export const ProcessingObjectSelectionProvider: React.FC<
  React.PropsWithChildren<ProcessingObjectSelectionProviderProps>
> = ({ resultLevel, children }) => {
  const [currentTab, setCurrentTab] = React.useState<string | undefined>(
    undefined
  )
  const scrollTargetRef = React.useRef<HTMLDivElement>(null)

  const openTab = React.useCallback((newCurrentTab: string | undefined) => {
    setCurrentTab(newCurrentTab)

    // Wait a moment before scrolling to give the component time to load and resize
    setTimeout(() => {
      scrollTargetRef.current?.scrollIntoView()
    }, 100)
  }, [])

  return (
    <ProcessingObjectSelectionContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        openTab,
        scrollTargetRef,
        resultLevel,
      }}
    >
      {children}
    </ProcessingObjectSelectionContext.Provider>
  )
}

export const useProcessingObjectTabs = createProviderHook(
  ProcessingObjectSelectionContext,
  'ProcessingObjectTabsProvider',
  'useProcessingObjectTabs'
)
