import { LogViewer } from '@dspace-internal/log-viewer'
import { Dialog, showSnackbar } from '@dspace-internal/ui-kit'
import { ContentCopy } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { Operation, OperationStatus } from '@simphera/shared/rest-clients'
import { useInterval } from '@simphera/shared/ui-simphera'
import { formatDistance } from 'date-fns'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import messages from '../../../utils/messages.res'
import { COLOR_BY_STATUS_MAP } from './NotificationListItem'
import { getNotification } from './api'
import { NOTIFICATION_ID_SEARCH_PARAM } from './constants'

const NOTIFICATION_DETAILS_POLL_MS = 2000

interface NotificationDialogProps {
  notification: Operation
}

function getDurationTime(notification: Operation): string {
  const durationEndOrNow = notification.finishedOn
    ? new Date(notification.finishedOn)
    : new Date()

  return formatDistance(new Date(notification.createdOn), durationEndOrNow, {
    includeSeconds: true,
  })
}

function shortenTitle(title: string): string {
  // for now we are shortening the title because they are too long
  // this is just a workaround which will be gone after further optimization of log entries and Notification Center
  return title.split(' from remote')[0]
}

const ValueTile: React.FC<{
  title: string
  value: string
  status?: OperationStatus
}> = ({ title, value, status }) => (
  <Box component="div" display="flex">
    <Typography variant="body2" fontWeight="500" width={100} flexShrink={0}>
      {title}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        overflowWrap: 'anywhere',
        color: status ? COLOR_BY_STATUS_MAP[status] : undefined,
        fontWeight: status ? 500 : undefined,
      }}
    >
      {value}
    </Typography>
  </Box>
)

export const NotificationDialog: React.FC<NotificationDialogProps> = ({
  notification: initialNotificationState,
}) => {
  const [searchParameters, setSearchParameters] = useSearchParams()
  const [notification, setNotification] = useState<Operation>(
    initialNotificationState
  )

  useInterval(() => {
    if (notification.finishedOn) {
      return
    }

    getNotification(notification.id).then(setNotification)
  }, NOTIFICATION_DETAILS_POLL_MS)

  const closeDialog = () => {
    // note: removing query parameter to close dialog
    searchParameters.delete(NOTIFICATION_ID_SEARCH_PARAM)
    setSearchParameters(searchParameters)
  }

  const copyAsJson = () => {
    navigator.clipboard.writeText(JSON.stringify(notification, null, 2))

    showSnackbar({
      message: messages.COPIED_NOTIFICATION_TO_CLIPBOARD(),
      appearance: 'info',
    })
  }

  return (
    <Dialog
      open
      title={shortenTitle(notification.title)}
      onClose={closeDialog}
      maxWidth="xl"
      fullWidth
      secondaryAction={{
        label: 'Copy as Json',
        icon: <ContentCopy />,
        onClick: copyAsJson,
      }}
    >
      <Box
        component="div"
        display="grid"
        gridTemplateColumns="1fr 1fr"
        width="100%"
        columnGap={6}
        marginBottom={6}
      >
        <ValueTile
          title="Created on"
          value={new Date(notification.createdOn).toLocaleString()}
        />
        <ValueTile
          title="Finished on"
          value={
            notification.finishedOn
              ? new Date(notification.finishedOn).toLocaleString()
              : '-'
          }
        />
        <ValueTile
          title="Status"
          value={notification.status}
          status={notification.status}
        />
        <ValueTile title="Created by" value={notification.createdByName} />
        <ValueTile
          title="Completed"
          value={`${notification.percentComplete}% (Duration: ${getDurationTime(notification)})`}
        />
        <ValueTile title="Type" value={notification.type} />
        <ValueTile title="Description" value={notification.title} />
        <ValueTile title="Operation Id" value={notification.id} />
      </Box>
      <LogViewer
        height="500px"
        defaultSortModel={{
          field: 'timestamp',
          sort: 'desc',
        }}
        logs={notification.logEntries.map((log) => ({
          timestamp: log.timeStamp!,
          creatorId: 'Core Service',
          message: log.title!,
          severity: log.severity!.toUpperCase() as 'INFO' | 'WARNING' | 'ERROR',
          additionalData: log.title!,
        }))}
      />
    </Dialog>
  )
}
