import { BreadcrumbRenderer, MetaHeader } from '@dspace-internal/ui-kit'
import React from 'react'
import { MaintenanceInfoHeader } from '../MaintenanceInfoHeader'
import { UserIndicator } from './UserIndicator'

export const Header: React.FC = () => {
  return (
    <>
      <MaintenanceInfoHeader />
      <MetaHeader
        breadcrumbs={<BreadcrumbRenderer showTrailingSeparator />}
        userIndicator={<UserIndicator />}
      />
    </>
  )
}
