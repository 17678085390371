import { fmt } from './messageUtils'

export const captions_res = {
  ADD_WIDGET: () => fmt('Add Widget'),
  ALL_WIDGETS: () => fmt('All Widgets'),
  AURELION_CONFIGURATION: () => fmt('AURELION Configuration'),
  AURELION_STREAMING: () => fmt('Video Stream'),
  AURELION: () => fmt('AURELION'),
  BACK: () => fmt('Back'),
  BGCOLOR: () => fmt('Background color'),
  BGIMAGE: () => fmt('Background image'),
  BIRD: () => fmt('Bird'),
  BORDERCOLOR: () => fmt('Border color '),
  CAMERA_TARGET: () => fmt('Camera Target'),
  CAMERA_VIEW: () => fmt('Camera View'),
  CHECKBOX_WIDGET_SETTINGS: () => fmt('Checkbox Settings'),
  CLONE_EXPERIMENT: () => fmt('Clone Experiment'),
  CONFIGURATION: () => fmt('Configuration'),
  CONTROL: () => fmt('Control'),
  CUSTOM: () => fmt('Custom'),
  DELETE_EXPERIMENT: () => fmt('Delete Experiment'),
  DELETE_EXPERIMENTS: () => fmt('Delete Experiments'),
  DELETE_WIDGET: () => fmt('Delete Widget'),
  DESCRIPTION: () => fmt('Description'),
  DISPLAY_SETTINGS: () => fmt('Display Settings'),
  EDIT_EXPERIMENT: () => fmt('Edit Experiment'),
  EGO: () => fmt('Ego'),
  EXPERIMENT_NOT_FOUND: () => fmt('Experiment not found'),
  EXPERIMENT_SETTINGS: () => fmt('Experiment Settings'),
  EXPERIMENT: () => fmt('Experiment'),
  EXPERIMENTS: () => fmt('Experiments'),
  FELLOW: () => fmt('Fellow'),
  FRAME_SETTINGS: () => fmt('Frame Settings'),
  LOGS_WIDGET_SETTINGS: () => fmt('Log Viewer Settings'),
  LOGS_WIDGET: () => fmt('Log Viewer'),
  MANEUVER_CONTROL_SHORTEND_TITLE: () => fmt('Maneuver'),
  MANEUVER_CONTROL: () => fmt('Maneuver Control'),
  MANEUVER_TIME_SHORT: () => fmt('Man. Time:'),
  MANEUVER_TIME: () => fmt('Maneuver Time:'),
  MAX: () => fmt('Max'),
  MEASUREMENT_TIME_SHORT: () => fmt('Meas. Time:'),
  MEASUREMENT_TIME: () => fmt('Measurement Time:'),
  MIN: () => fmt('Min'),
  NAME: () => fmt('Name'),
  NEW_EXPERIMENT: () => fmt('New Experiment'),
  NO_EXPERIMENTS_AVAILABLE: () => fmt('No experiments available'),
  NONE: () => fmt('None'),
  NUMERIC_INPUT_SETTINGS: () => fmt('Numeric Input Settings'),
  ORIGIN: () => fmt('Origin'),
  PANEL: () => fmt('Panel'),
  PERMISSION_DENIED: () => fmt('Permission denied'),
  PLOTTER_SETTINGS: () => fmt('Plotter Settings'),
  PREDEFINEDBGCOLORS: () => fmt('Predefined background colors'),
  PREDEFINEDBORDERCOLORS: () => fmt('Predefined border colors'),
  PUSH_BUTTON_SETTINGS: () => fmt('Push Button Settings'),
  RADIO_BUTTON_SETTINGS: () => fmt('Radio Button Settings'),
  SCENARIO_PARAMETERS: () => fmt('Scenario Parameters'),
  SCENARIO_SELECTION: () => fmt('Scenario'),
  TE_SELECTION: () => fmt('Test Environment'),
  SCENARIO: () => fmt('Scenario'),
  SCENE_VIEWER: () => fmt('Scene Viewer'),
  SELECT_SUT: () => fmt('Select a SUT'),
  SELECT_TEST_ENVIRONMENT: () => fmt('Select a Test Environment'),
  SETTINGS_LABEL_AUTOMANEUVERSTART: () =>
    fmt('Automatically start maneuver after initializing'),
  SETTINGS_LABEL_TIMEOUT: () => fmt('Automatic experiment shutdown time'),
  SHOW_LESS: () => fmt('Show less'),
  SHOW_MORE: () => fmt('Show more'),
  SIDE: () => fmt('Side'),
  SMOOTHSCROLLMODE: () => fmt('Smooth Scrolling'),
  STATE: () => fmt('State'),
  STREAM_SETTINGS: () => fmt('Stream Settings'),
  STREAM: () => fmt('Stream'),
  SUT_SELECTION: () => fmt('SUT'),
  SUT: () => fmt('SUT'),
  SYSTEM_UNDER_TEST: () => fmt('System Under Test'),
  SYSTEM: () => fmt('System'),
  TAB: () => fmt('Tab'),
  TE: () => fmt('TE'),
  TEST_ENVIRONMENT: () => fmt('Test Environment'),
  TIME_PLOTTER: () => fmt('Time Plotter'),
  TIME_PLOTTER_NG: () => fmt('New Time Plotter'),
  TIME: () => fmt('Time'),
  TITLE_VISIBLE: () => fmt('Title Visible'),
  TITLE: () => fmt('Title'),
  UNABLE_TO_CLONE_EXPERIMENT: () => fmt('Unable to clone Experiment'),
  UNABLE_TO_DELETE_EXPERIMENT: () => fmt('Unable to delete Experiment'),
  PUSH_BUTTON: () => fmt('Push Button'),
  RADIO_BUTTON: () => fmt('Radio Button'),
  ORIENTATION: () => fmt('Orientation'),
  HORIZONTAL_ALIGNMENT: () => fmt('Horizontal Alignment'),
  VERTICAL_ALIGNMENT: () => fmt('Vertical Alignment'),
  CHECKBOX: () => fmt('CheckBox'),
  FRAME: () => fmt('Frame'),
  DISPLAY: () => fmt('Display'),
  NUMERIC_INPUT: () => fmt('Numeric Input'),
  LABEL: () => fmt('Label'),
  BACKGROUND_COLOR: () => fmt('Background Color'),
  LABEL_FONT_COLOR: () => fmt('Label Font Color'),
  VALUE_FONT_COLOR: () => fmt('Value Font Color'),
  LABEL_FONT_SIZE: () => fmt('Label Font Size'),
  VALUE_FONT_SIZE: () => fmt('Value Font Size'),
  BUTTON_FONT_SIZE: () => fmt('Button Font Size'),
  BUTTON_FONT_COLOR: () => fmt('Button Font Color'),
  ON_VALUE: () => fmt('On Value'),
  OFF_VALUE: () => fmt('Off Value'),
  NUMBER_FORMAT: () => fmt('Number Format'),
  PRECISION: () => fmt('Precision'),
  BUTTONS: () => fmt('Buttons'),
  BUTTON_VALUE_VISIBLE: () => fmt('Button Value Visible'),
  CAPTION: () => fmt('Caption'),
  CAPTION_VISIBLE: () => fmt('Caption Visible'),
  UNABLE_TO_INITIATE_EXPERIMENT: () => fmt('Unable to initiate Experiment'),
  UNABLE_TO_SAVE_EXPERIMENT: () => fmt('Unable to save Experiment'),
  UNABLE_TO_SHUTDOWN_EXPERIMENT: () => fmt('Unable to shut down Experiment'),
  VALUE: () => fmt('Value'),
  VEHICLE_SELECTION: () => fmt('Vehicle'),
  VEHICLE: () => fmt('Vehicle'),
  VISUALIZATION_PAUSE_SHORTENED_TITLE: () => fmt('Pause'),
  VISUALIZATION_PAUSE: () => fmt('Visualization Pause'),
  VISUALIZATION: () => fmt('Visualization'),
  X_AXIS_RANGE: () => fmt('X-Axis Range'),
  TIME_CURSOR: () => fmt('Time Cursor'),
  SET_TIME_CURSOR: () => fmt('Set Time Cursor'),
  SIMULATION_TIME_MMSSFFF: () => fmt('Simulation Time (mm:ss.fff)'),
  SIMULATION_TIME_S: () => fmt('Simulation Time (s)'),
}
