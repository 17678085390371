import { Button, showSnackbar } from '@dspace-internal/ui-kit'
import { Save } from '@mui/icons-material'
import {
  GitWorkspaceApiError,
  PatchGitWorkspaceParameters,
  createGitWorkspace,
} from '@simphera/shared/rest-clients'
import {
  CustomPageHeader,
  usePrompt,
  useSimpheraBreadcrumbs,
} from '@simphera/shared/ui-simphera'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import messages from '../../../utils/messages.res'
import { GitWorkspaceFormFields } from '../GitWorkspaceFormFields/GitWorkspaceFormFields'

const defaultValues: PatchGitWorkspaceParameters = {
  name: '',
  description: '',
  repositoryUrl: '',
  repositoryUsername: '',
  repositoryPassword: '',
  pathToProjectYaml: '',
  lfsUsername: '',
  lfsPassword: '',
  checkoutFolders: '',
}

export const GitWorkspaceCreatePage = () => {
  const formProps = useForm<PatchGitWorkspaceParameters>({
    defaultValues,
    mode: 'all',
  })
  const { formState, handleSubmit, reset } = formProps
  usePrompt(`\n${messages.UNSAVED_CHANGES()}`, formState.isDirty)

  const navigate = useNavigate()
  useSimpheraBreadcrumbs()

  const handleSave: SubmitHandler<PatchGitWorkspaceParameters> = async (
    formData
  ) => {
    if (formState.isSubmitting || formState.isSubmitted) {
      return
    }

    createGitWorkspace(formData)
      .then((data) => {
        reset(defaultValues)
        showSnackbar({
          appearance: 'info',
          message: messages.GIT_WORKSPACE_CREATED(),
        })

        // navigating after timeout so that usePrompt can be disabled. ugly, but prettier than the other options
        setTimeout(() => navigate(`/gitWorkspaces/${data.id}`))
      })
      .catch((error: GitWorkspaceApiError) => {
        console.error(error)
        showSnackbar({
          appearance: 'error',
          message: messages.GIT_WORKSPACE_CREATE_ERROR(error.message),
        })
      })
  }

  const SaveButton = () => (
    <Button
      color="primary"
      variant="contained"
      icon={<Save />}
      label="Save"
      type="submit"
      value="Submit"
      disabled={!formState.isValid}
    />
  )

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSave)}>
        <CustomPageHeader
          id="GIT_WORKSPACE_CREATE"
          primaryAction={<SaveButton />}
          subtitle="Create Git Workspace"
          title="New Git Workspace"
        />
        <GitWorkspaceFormFields />
      </form>
    </FormProvider>
  )
}
