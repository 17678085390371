import React from 'react'

import { createProviderHook } from '@simphera/shared/ui-simphera'

export interface VideoTimeCursorContextData {
  currentTime: number | undefined
  setCurrentTime: (currentTime: number) => void
}

const VideoTimeCursorContext = React.createContext<
  VideoTimeCursorContextData | undefined
>(undefined)

export const VideoTimeCursorProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentTime, setCurrentTime] = React.useState<number | undefined>(
    undefined
  )

  return (
    <VideoTimeCursorContext.Provider value={{ currentTime, setCurrentTime }}>
      {children}
    </VideoTimeCursorContext.Provider>
  )
}

export const useVideoTimeCursor = createProviderHook(
  VideoTimeCursorContext,
  'VideoTimeCursorProvider',
  'useVideoTimeCursor'
)
