import { ProcessingObjectResultState } from '../../../types'
export interface KpiVisualizationData {
  verdict: ProcessingObjectResultState
  verdictReason: string
  properties: KpiVisualizationProperty[]
}

export type KpiVisualizationProperty =
  | GroupProperty
  | ReferenceProperty
  | SubheaderProperty
  | StringProperty
  | TileProperty
  | VerdictTimelineProperty
  | StringTimelineProperty
  | HistogramProperty
  | LinePlotProperty
  | ScatterPlotProperty
  | BarChartProperty
  | VideoProperty

export interface KpiVisualizationPropertyBase<TTypeString> {
  id: string
  name: string
  type: TTypeString
}

// ######################################
// ## Group Property
// ######################################
export interface GroupProperty extends KpiVisualizationPropertyBase<'Group'> {
  properties: KpiVisualizationProperty[]
  heading: string
  syncXAxes: boolean
}

// ######################################
// ## Reference Property
// ######################################
export interface ReferenceProperty
  extends KpiVisualizationPropertyBase<'Reference'> {
  processingObjectId: string
  targetPropertyId: string
}

// ######################################
// ## Heading Property
// ######################################
export interface SubheaderProperty
  extends KpiVisualizationPropertyBase<'Subheader'> {
  text: string
}

// ######################################
// ## String Property
// ######################################
export interface StringProperty extends KpiVisualizationPropertyBase<'String'> {
  data: string
}

// ######################################
// ## Tile Property
// ######################################
export interface TileProperty extends KpiVisualizationPropertyBase<'Tile'> {
  data: Record<string, string | number | undefined>
}

// ######################################
// ## Timeline Base Types
// ######################################
export interface TimelineEntry<TValue> {
  start: number
  end: number | undefined
  value: TValue
  comment: string | undefined
}

export interface TimelineBase<TTypeString, TValue>
  extends KpiVisualizationPropertyBase<TTypeString> {
  data: TimelineEntry<TValue>[]
  xAxisUnit: string | undefined
}

// ######################################
// ## Verdict Timeline Property
// ######################################
export type VerdictTimelineVerdict = 'PASSED' | 'FAILED'

export type VerdictTimelineProperty = TimelineBase<
  'VerdictTimeline',
  VerdictTimelineVerdict
>

// ######################################
// ## String Timeline Property
// ######################################
export type StringTimelineProperty = TimelineBase<'StringTimeline', string>

// ######################################
// ## Chart Base Types
// ######################################
interface ChartPropertyBase<TTypeString, TSeries>
  extends KpiVisualizationPropertyBase<TTypeString> {
  xAxisLabel: string | undefined
  yAxisLabel: string | undefined
  xAxisUnit: string | undefined
  yAxisUnit: string | undefined
  series: TSeries[]
}

// ######################################
// ## Histogram Property
// ######################################
export type HistogramProperty = ChartPropertyBase<'Histogram', HistogramSeries>

export interface HistogramSeries {
  name: string
  normalDistribution: { mean: number; standardDeviation: number } | undefined
  median: number | undefined
  bins: HistogramBin[]
}

export interface HistogramBin {
  min: number
  max: number
  frequency: number
}

// ######################################
// ## Line Plot Property
// ######################################
export type LinePlotProperty = ChartPropertyBase<'LinePlot', LinePlotSeries>

// ######################################
// ## Scatter Plot Property
// ######################################
export type ScatterPlotProperty = ChartPropertyBase<
  'ScatterPlot',
  LinePlotSeries
>

export interface LinePlotSeries {
  name: string
  points: LinePlotPoint[]
}

export interface LinePlotPoint {
  x: number
  y: number
}

// ######################################
// ## Bar Chart Property
// ######################################
export type BarChartGroupMode = 'GROUPED' | 'STACKED'
export type BarChartLayout = 'VERTICAL' | 'HORIZONTAL'

export interface BarChartBar {
  name: string
  value: number
}

export interface BarChartGroup {
  name: string
  bars: BarChartBar[]
}

export interface BarChartProperty
  extends KpiVisualizationPropertyBase<'BarChart'> {
  groupMode: BarChartGroupMode
  groups: BarChartGroup[]
  layout?: BarChartLayout
  min?: number
  max?: number
  valueAxisLabel?: string
  valueAxisUnit?: string
  indexAxisLabel?: string
}

// ######################################
// ## Video Property
// ######################################
export interface VideoProperty extends KpiVisualizationPropertyBase<'Video'> {
  videoArtifactName: string
  startOffsetTime: number
}

const objectKeysToCamelCase = (value: unknown): unknown => {
  if (Array.isArray(value)) {
    return (value as unknown[]).map((item) => objectKeysToCamelCase(item))
  } else if (value instanceof Object) {
    const converted: Record<string, unknown> = {}

    Object.keys(value).forEach((key) => {
      const camelCaseKey = key[0].toLowerCase().concat(key.substring(1))
      converted[camelCaseKey] = objectKeysToCamelCase(
        (value as Record<string, unknown>)[key]
      )
    })

    return converted
  } else {
    return value
  }
}

export const getAllProperties = (properties: KpiVisualizationProperty[]) => {
  let allProperties: KpiVisualizationProperty[] = properties.filter(
    (property) => property.type !== 'Group'
  )

  properties
    .filter((property): property is GroupProperty => property.type === 'Group')
    .forEach((group) => {
      allProperties = allProperties.concat(getAllProperties(group.properties))
    })

  return allProperties
}

export const isProperty = <TProperty extends KpiVisualizationProperty>(
  propertyType: TProperty['type']
) => {
  return (property: KpiVisualizationProperty): property is TProperty =>
    property.type === propertyType
}

export const parseKpiVisualizationJson = (
  jsonString: string
): KpiVisualizationData => {
  const json = JSON.parse(jsonString)

  const data = objectKeysToCamelCase(json) as KpiVisualizationData

  getAllProperties(data.properties).forEach((property) => {
    if (!property.id) {
      property.id = property.name
    }
  })

  return data
}
