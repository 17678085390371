import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import React from 'react'
import { PATHS } from '../../utils'

const ExternalLink = styled.a`
  color: ${colors.blue_60};
  text-decoration: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${colors.blue_50};
    text-decoration: underline;
  }
`

/**
 * Component that renders a link to swagger, based on localhost or cluster use case.
 */
export const SwaggerLink: React.FC = () => {
  let swaggerUrl = PATHS.SWAGGER()

  if (window.location.href.search('localhost') !== -1) {
    // In local development, swagger is hosted on a different port.
    // Hence, we have to prefix the url with localhost address.
    swaggerUrl = 'http://localhost:10015' + swaggerUrl
  }
  return <ExternalLink href={swaggerUrl}>REST Api Documentation</ExternalLink>
}
