import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Operation,
} from '@apollo/client'
import type { NetworkError } from '@apollo/client/errors'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import type { GraphQLError } from 'graphql'

import { traceparent } from 'tctx'
import { API_GATEWAY_HOST } from '../config'
import { customFetch } from './customFetch'

// Instantiate logout fn and token with initial values
let logoutFn = () => {
  console.warn('No logout function set')
}
let accessToken = ''

const setAuthHeader = () => {
  const traceId = traceparent.make().toString()
  return {
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'x-request-id': traceId,
      traceparent: traceId,
      'GraphQL-preflight': 1, // for GraphQL Upload scalar
    },
  }
}

const link = createUploadLink({
  uri: API_GATEWAY_HOST(),
  fetch: customFetch,
}) as unknown as ApolloLink

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  checkErrors(graphQLErrors, networkError, operation)
})

const checkErrors = (
  graphQLErrors: readonly GraphQLError[] | undefined,
  networkError: NetworkError | undefined,
  operation: Operation
): void => {
  // operation context contains status code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (operation.getContext()['response'].status === 401) {
    if (networkError) {
      // override parse error with auth failed message
      networkError.message = 'Authentication failed!'
      networkError.name = 'Authentication Error'
    }
    logoutFn()
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${String(
          locations
        )}, Path: ${String(path)}`
      )
    })
  }
  if (networkError) {
    console.error(
      `[Network error]: ${networkError.name}: ${networkError.message}`
    )
  }
}

const authLink = setContext(() => {
  return setAuthHeader()
})

// Function to update token and logout function from outside of this file
export const apolloClientUpdate = (token: string, logout: () => void) => {
  logoutFn = logout
  accessToken = token
}

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink.concat(link)]),
})
