import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/gitWorkspace'
import { applyClientConfig } from '../clientConfig'

applyClientConfig(OpenAPI, { baseUrl: GlobalConfig.project.REST_API_URL })

export {
  GitWorkspacePreviewService as GitWorkspaceService,
  type GitWorkspaceResponseDto as GitWorkspaceResponse,
} from '../../generated/gitWorkspace'

export type GitWorkspaceApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}

export * from './utils'
