import { colors } from '@dspace-internal/ui-kit'
import { Box, CircularProgress, Typography, styled } from '@mui/material'
import { TextWithOverflow } from '@simphera/shared/ui-simphera'
import { resultStateColors } from '../../../ResultIcons'

interface StyleProps {
  customColor: string | undefined | null
}

const ProgressBottom = styled(CircularProgress)(() => ({
  color: colors.gray_20,
  verticalAlign: 'middle',
}))

const Progress = styled(CircularProgress)<StyleProps>((props: StyleProps) => ({
  verticalAlign: 'middle',
  position: 'absolute',
  left: 0,
  color: props.customColor ?? resultStateColors.processing,
}))

const ProgressPosition = styled('div')(() => ({
  position: 'relative',
}))

const ProgressContainer = styled('span')(() => ({
  verticalAlign: 'middle',
  margin: '0px 20px 0px 0px',
  display: 'flex',
  alignItems: 'center',
}))

export interface ProgressDisplayProps {
  percentage: number | undefined | null
  message: string | undefined | null
  color: string | undefined | null
}

export const ProgressDisplay: React.FC<ProgressDisplayProps> = ({
  percentage,
  message,
  color,
}) => {
  // set percentage to undefined for non-valid progress
  if (
    percentage === undefined ||
    percentage === null ||
    percentage < 0 ||
    percentage > 99
  ) {
    percentage = undefined
  }

  return (
    <ProgressContainer>
      {(percentage || percentage === 0) && (
        <Box
          component="div"
          position="relative"
          display="inline-flex"
          marginRight="24px"
        >
          <ProgressPosition>
            <ProgressBottom
              variant="determinate"
              value={100}
              size={35}
              thickness={4.0}
            />
            <Progress
              customColor={color}
              variant="determinate"
              value={percentage}
              size={35}
              thickness={4.0}
            />
            <Box
              component="div"
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="initial"
              >{`${Math.round(percentage)}%`}</Typography>
            </Box>
          </ProgressPosition>
        </Box>
      )}
      <TextWithOverflow data-testid="progress_message">
        {message || ''}
      </TextWithOverflow>
    </ProgressContainer>
  )
}
