import { ApolloProvider } from '@apollo/client'
import { UiKitProvider } from '@dspace-internal/ui-kit'
import {
  AuthProvider,
  apolloAuthenticationClient,
} from '@simphera/shared/state'
import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { routes } from './routes'

export const App: React.FC = () => {
  const router = createBrowserRouter(routes)

  return (
    <UiKitProvider>
      <AuthProvider>
        <ApolloProvider client={apolloAuthenticationClient}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </AuthProvider>
    </UiKitProvider>
  )
}
