import { useDroppable } from '@dnd-kit/core'
import styled from '@emotion/styled'
import { mdiEyeOff } from '@mdi/js'
import { Box } from '@mui/material'
import { captions_res } from '../../../../resources/captions'
import {
  DroppableContainer,
  DroppableContainerHeading,
} from './DroppableContainer'
import { ParameterDraggable } from './ParameterDraggable'
import { ParameterDraggablePlaceholder } from './ParameterDraggable.styles'

const UnplottedParametersDroppableContainer = styled(DroppableContainer)`
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;

  border: none;
`

const unplottedParametersDroppableId = 'unplottedParameters'

export interface UnplottedParametersDroppableProps {
  unplottedParameters: string[]
}

export const UnplottedParametersDroppable: React.FC<
  UnplottedParametersDroppableProps
> = ({ unplottedParameters }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: unplottedParametersDroppableId,
  })

  return (
    <UnplottedParametersDroppableContainer ref={setNodeRef} isHovered={isOver}>
      <DroppableContainerHeading
        iconPath={mdiEyeOff}
        label="Unplotted Parameters"
      />
      <Box
        component="div"
        display="grid"
        gridTemplateColumns="1fr"
        rowGap="8px"
      >
        {unplottedParameters.length > 0 ? (
          unplottedParameters.map((parameterName) => (
            <ParameterDraggable
              key={parameterName}
              parameterName={parameterName}
            />
          ))
        ) : (
          <ParameterDraggablePlaceholder>
            {captions_res.PARAMETER_SELECTION_DROPZONE()}
          </ParameterDraggablePlaceholder>
        )}
      </Box>
    </UnplottedParametersDroppableContainer>
  )
}
