import { CustomLayerProps } from '@nivo/line'

export const getScaleFunctions = (
  props: Pick<CustomLayerProps, 'xScale' | 'yScale'>
) => {
  return {
    xScale: props.xScale as unknown as (x: number) => number,
    yScale: props.yScale as unknown as (y: number) => number,
  }
}
