import { mdiFunctionVariant } from '@mdi/js'
import Icon from '@mdi/react'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { PATHS, SidebarItemInterpolate } from '@simphera/shared/ui-simphera'
import React from 'react'
import { KpiDetailsPage } from './components/KpiDetailsPage'

const KpiListPage = React.lazy(() => import('./components/KpiListPage'))

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.KPI_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="KPIs"
      icon={<Icon path={mdiFunctionVariant} size={1} />}
      to={PATHS.KPIS.LIST(':projectId')}
    />,
  ],
  routes: [
    {
      path: PATHS.KPIS.LIST(':projectId'),
      element: <KpiListPage />,
    },
    {
      path: PATHS.KPIS.DETAIL(':projectId', ':kpiId'),
      element: <KpiDetailsPage />,
    },
  ],
}
