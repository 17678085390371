import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  PATHS,
  SidebarItemInterpolate,
  SystemUnderTestIcon,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'

const SUTOverview = React.lazy(() => import('./components/SUTListPage'))

const SUTDetailsPage = React.lazy(
  () => import('./components/SUTDetailsPage/SUTDetailsPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.SYSTEMUNDERTEST_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="SUTs"
      to={PATHS.SUTS.LIST(':projectId')}
      icon={<SystemUnderTestIcon />}
    />,
  ],
  routes: [
    {
      path: PATHS.SUTS.LIST(':projectId'),
      element: wrapComponentInProviders(SUTOverview),
    },
    {
      path: PATHS.SUTS.DETAIL(':projectId', ':sutId'),
      element: wrapComponentInProviders(SUTDetailsPage),
    },
  ],
}
