import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

const ParameterDraggableSized = styled.div`
  border-radius: 4px;
  width: 360px;
  height: 44px;
`

export interface ParameterDraggableContainerProps {
  grabbed: boolean
  hide: boolean
}

export const ParameterDraggableContainer = styled(
  ParameterDraggableSized
)<ParameterDraggableContainerProps>`
  cursor: ${(props) => (props.grabbed ? 'grabbing' : 'grab')};

  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid gray;
  background-color: ${colors.white};

  font-size: 18px;

  svg {
    color: ${colors.gray_80};
  }
  div {
    padding: 0px 8px;
    line-height: 44px;
  }

  ${(props) => (props.hide ? 'visibility: hidden;' : '')}
`

export const ParameterDraggablePlaceholder = styled(ParameterDraggableSized)`
  border: 1px dashed ${colors.gray_80};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors.gray_80};
  font-size: 16px;

  border: none;
`
