import { TextField } from '@dspace-internal/ui-kit'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IEditMode, IReadOnly } from './types'

export const RepositoryPasswordInput: React.FC<IReadOnly & IEditMode> = ({
  isReadOnly = false,
  isEditMode = false,
}) => {
  const { control, formState } =
    useFormContext<Required<{ repositoryPassword: string }>>()
  const error = formState.errors.repositoryPassword

  return (
    <Controller
      control={control}
      name="repositoryPassword"
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          name={field.name}
          error={Boolean(error)}
          label="Password"
          type="password"
          size="small"
          autoComplete="off"
          fullWidth
          helperText={error?.message}
          disabled={isReadOnly}
          {...(isEditMode
            ? {
                InputLabelProps: { shrink: true },
                placeholder: '•••••••',
              }
            : {})}
        />
      )}
    />
  )
}
