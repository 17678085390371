// prettier-ignore
export const PATHS = {
  ROOT: () =>  `/`,
  AUTHENTICATION: () =>  `/authentication`,
  SWAGGER: () => '/api/swagger/index.html',
  APIKEYS: {
    LIST: () => `/api-keys`,
    NEW: () => `/api-keys/new`,
  },
  PROJECTS: {
    LIST: () => `/projects`,
    NEW: () => `/projects/new`,
    IMPORT: () => `/projects/import`,
    DETAILS: (projectId: string) => `/projects/${projectId}`,
    EDIT: (projectId: string) => `/projects/${projectId}/edit`,
  },
  GITWORKSPACES: {
    NEW: () => `/gitWorkspaces/new`,
    DETAILS: (gitWorkspaceId: string) => `/gitWorkspaces/${gitWorkspaceId}`,
    EDIT: (gitWorkspaceId: string) => `/gitWorkspaces/${gitWorkspaceId}/edit`,
  },
  TESTENVIRONMENTS: {
    LIST: (projectId: string) => `/projects/${projectId}/testenvironments`,
    DETAIL: (projectId: string, testEnvironmentId: string) => `/projects/${projectId}/testenvironments/${testEnvironmentId}`,
    DETAIL_LINK_DATE: (projectId: string, teId: string, linkDate: string) => `/projects/${projectId}/testenvironments/${teId}?linkDate=${String(linkDate)}`,
  },
  SUTS: {
    LIST: (projectId: string) => `/projects/${projectId}/suts`,
    DETAIL: (projectId: string, sutId: string) => `/projects/${projectId}/suts/${sutId}`,
    DETAIL_LINK_DATE: (projectId: string, sutId: string, linkDate: string) => `/projects/${projectId}/suts/${sutId}?linkDate=${String(linkDate)}`,
  },
  KPIS: {
    LIST: (projectId: string) => `/projects/${projectId}/kpis`,
    DETAIL: (projectId: string, kpiId: string) => `/projects/${projectId}/kpis/${kpiId}`,
  },
  VEHICLES: {
    LIST: (projectId: string) => `/projects/${projectId}/vehicles`,
    CREATE: (projectId: string) => `/projects/${projectId}/vehicles/create`,
    DETAIL: (projectId: string, vehicleId: string) => `/projects/${projectId}/vehicles/${vehicleId}`,
    DETAIL_LINK_DATE: (projectId: string, vehicleId: string, linkDate: string) => `/projects/${projectId}/vehicles/${vehicleId}?linkDate=${String(linkDate)}`,
  },
  SENSORS: {
    LIST: (projectId: string) => `/projects/${projectId}/sensors`,
    DETAIL: (projectId: string, sensorId: string) => `/projects/${projectId}/sensors/${sensorId}`,
    CREATE: (projectId: string, sensorType: string) => `/projects/${projectId}/sensors/create/${sensorType}`,
  },
  EXPERIMENTS: {
    LIST: (projectId: string) => `/projects/${projectId}/experiments`,
    DETAIL: (projectId: string, experimentId: string) => `/projects/${projectId}/experiments/${experimentId}`,
    CREATE: (projectId: string,) => `/projects/${projectId}/experiments/create`,
    EDIT: (projectId: string, experimentId: string) => `/projects/${projectId}/experiments/${experimentId}/edit`,
  },
  SCENARIOS: {
    LIST: (projectId: string) => `/projects/${projectId}/scenarios`,
    IMPORT: (projectId: string) => `/projects/${projectId}/scenarios/import`,
    DETAIL: (projectId: string, scenarioId: string) => `/projects/${projectId}/scenarios/${scenarioId}`,
    DETAIL_LINK_DATE: (projectId: string, scenarioId: string, linkDate: string) => `/projects/${projectId}/scenarios/${scenarioId}?linkDate=${String(linkDate)}`,
  },
  DRT: {
    HOME: (projectId: string) => `/projects/${projectId}/datareplay/suites`,
    SUITES_LIST: (projectId: string) => `/projects/${projectId}/datareplay/suites`,
    SUITES_NEW: (projectId: string) => `/projects/${projectId}/datareplay/suites/new`,
    SUITES_DETAIL: (projectId: string, suiteId: string) => `/projects/${projectId}/datareplay/suites/${suiteId}`,
    TESTCASES_NEW: (projectId: string) => `/projects/${projectId}/datareplay/testcases/new`,
    TESTCASES_DETAIL: (projectId: string, testCaseId: string) => `/projects/${projectId}/datareplay/testcases/${testCaseId}`,
    RUNS_LIST: (projectId: string) => `/projects/${projectId}/datareplay/runs`,
    RUNS_DETAIL: (projectId: string, runId: string) => `/projects/${projectId}/datareplay/runs/${runId}`,
    RUNS_TESTCASE: (projectId: string, runId: string, testCaseId: string) => `/projects/${projectId}/datareplay/runs/${runId}/testcases/${testCaseId}`,
    RUNS_TESTCASE_RESULT: (projectId: string, runId: string, testCaseId: string, resultId: string) => `/projects/${projectId}/datareplay/runs/${runId}/testcases/${testCaseId}/results/${resultId}`,
    RESULT_DETAIL: (projectId: string, resultId: string) => `/projects/${projectId}/datareplay/results/${resultId}`,
  },
  STPEBT: {
    HOME: (projectId: string) => `/projects/${projectId}/step-based`,
    SUITES_LIST: (projectId: string) => `/projects/${projectId}/step-based/suites`,
    SUITES_DETAIL: (projectId: string, suiteId: string) => `/projects/${projectId}/step-based/suites/${suiteId}`,
    SUITES_NEW: (projectId: string) => `/projects/${projectId}/step-based/new`,
    SUITES_TESTCASES_NEW : (projectId: string, suiteId: string) => `/projects/${projectId}/step-based/suites/${suiteId}/testcases/new`,
    SUITES_TESTCASES_DETAIL : (projectId: string, suiteId: string, logicalTestCaseId: string) => `/projects/${projectId}/step-based/suites/${suiteId}/testcases/${logicalTestCaseId}`,
    RUNS_LIST: (projectId: string) => `/projects/${projectId}/step-based/runs`,
    RUNS_DETAIL: (projectId: string, runId: string) => `/projects/${projectId}/step-based/runs/${runId}`,
    RUNS_TESTCASE_RESULT: (projectId: string, runId: string, testCaseResultId: string) => `/projects/${projectId}/step-based/runs/${runId}/testcases/${testCaseResultId}`,
    LIRBRARIES_LIST: (projectId: string) => `/projects/${projectId}/step-based/libraries`,
    LIRBRARIES_TESTCASES_DETAIL: (projectId: string, libraryId: string, testCaseId: string) => `/projects/${projectId}/step-based/libraries/${libraryId}/testcases/${testCaseId}`,
  },
  SCBT: {
    TESTCASE_NEW: (projectId: string) => `/projects/${projectId}/scenario-based/testcases/new`,
    TESTCASE_DETAIL: (projectId: string, testCaseId: string) => `/projects/${projectId}/scenario-based/testcases/${testCaseId}`,
    SUITE_LIST: (projectId: string) => `/projects/${projectId}/scenario-based/suites`,
    SUITE_NEW: (projectId: string) => `/projects/${projectId}/scenario-based/suites/new`,
    SUITE_DETAIL: (projectId: string, suiteId: string) => `/projects/${projectId}/scenario-based/suites/${suiteId}`,
    RESULT: (projectId: string, resultId: string) =>  `/projects/${projectId}/scenario-based/results/${resultId}`,
    RUNS_LIST: (projectId: string) => `/projects/${projectId}/scenario-based/runs`,
    RUNS_DETAIL: (projectId: string, runId: string) => `/projects/${projectId}/scenario-based/runs/${runId}`,
    RUNS_TESTCASE: (projectId: string, runId: string,testCaseId : string) =>  `/projects/${projectId}/scenario-based/runs/${runId}/testcases/${testCaseId}`,
  },
}

// prettier-ignore
export const QUERY_PARAMS = {
  PROJECTS: {
    SHOW_CREATE_OPTIONS: () => 'show-create-options',
  },
}
