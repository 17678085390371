/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportRequest } from '../models/ImportRequest';
import type { OperationResponse } from '../models/OperationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImportService {

    /**
     * Imports a project from git into the provided workspace.
     *
     * Imports a project from git into the provided workspace.
     * ---
     * * Returns an operation id which can be used to query the state of the operation.
     * @returns OperationResponse Accepted
     * @throws ApiError
     */
    public static postImportProjects({
        requestBody,
    }: {
        requestBody?: ImportRequest,
    }): CancelablePromise<OperationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import/projects',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Imports a project from a zip file into SIMPHERA.
     *
     * Imports a project from a zip file into SIMPHERA.
     * ---
     * *Returns an object containing an id which can be used to query the state of the operation.
     * @returns OperationResponse Accepted
     * @throws ApiError
     */
    public static postImportProjectsFromzip({
        formData,
    }: {
        /**
         * Uploads a zip file with metadata information
         */
        formData?: {
            /**
             * The relative path to the project yaml file inside the zip archive.
             */
            pathToProjectYaml?: string;
            /**
             * The zip file (binary).
             */
            file: Blob;
        },
    }): CancelablePromise<OperationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import/projects/fromzip',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
